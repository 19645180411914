import { LiaHandPointer } from "react-icons/lia";

const SectionSec = ({ title, para }) => {
  return (
    <div className="section_content">
      <h5>
        <LiaHandPointer />
        {title || "-"}
      </h5>
      <p>{para || "-"}</p>
    </div>
  );
};

export default SectionSec;
