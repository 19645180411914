import NormalButton from "../../../components/normalButton/NormalButton";
import { images } from "../../../utils/images";
import "./HomeShareApp.scss";
import { motion } from "framer-motion";

const HomeShareApp = () => {
  return (
    <section className="share_app p_t">
      <div className="content_wrap">    
        <motion.div 
          initial={{ translateY: 48, scaleX: 0.8, opacity: 0 }}
          whileInView={{ translateY: 0, scaleX: 1, opacity: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
        className="banner_wraper">
          <div className="left">
            <h3>Share the App with Friends and Colleagues</h3>
          </div>
          <div className="sketch">
            <img src={images.shareSketch} alt="Illustration of a person holding a globe with two speech bubbles." />
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default HomeShareApp;
