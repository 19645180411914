import "./PrimaryBtn.scss";
import { MdKeyboardArrowRight } from "react-icons/md";
import { motion } from "framer-motion";

const PrimaryBtn = ({ title, clickHandler, delay }) => {
  return (
    <motion.button
      initial={{ scale: 0, opacity: 0 }}
      whileInView={{ scale: 1, opacity: 1 }}
      transition={{
        duration: 0.6,
        ease: "easeInOut",
        delay:delay,
      }}
      type="button"
      className="primary_btn"
      onClick={clickHandler}
    >
      {title}
      <span>
        <MdKeyboardArrowRight />
      </span>
    </motion.button>
  );
};
export default PrimaryBtn;
