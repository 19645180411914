import "./CourseLanding.scss";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const CourseLanding = ({name, p1, img}) => {

const navigate = useNavigate();

  return (
    <section className="course_landing p_t p_b">
      <div className="content_wrap">
        <motion.div
        initial={{ opacity: 0,scale:0.6, translateY: 60 }}
        whileInView={{ opacity: 1,scale:1, translateY: 0 }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
        className="landing_card p_t p_b">
          <motion.h1
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay:0.1 }}
            className="big"
          >
            {name || "-"}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.3 }}
          >
           {p1 || "-"}
          </motion.p>
          <PrimaryBtn title="Contact" delay={0.5} clickHandler={() => navigate("/contact")} />
        </motion.div>
        <div className="img_line">
          <img src={img} alt="course" />
        </div>
      </div>
    </section>
  );
};
export default CourseLanding;
