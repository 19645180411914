import WebLayout from "../../components/weblayout/WebLayout";
import ErrorSec from "./errorSec/ErrorSec";

const ErrorPage = () => {
  return (
    <WebLayout>
      <ErrorSec />
    </WebLayout>
  );
};

export default ErrorPage;
