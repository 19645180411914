import axios from "axios";
import ScreenTitle from "../screenTitle/ScreenTitle";
import "./HelpCenter.scss";
import { motion } from "framer-motion";
import { baseUrl, helpCenter } from "../../../../../utils/apiData";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useContext, useState } from "react";
import { webContext } from "../../../../../webContext/WebContext";
import TextError from "../../../../../components/textError/TextError";

const HelpCenter = ({ setScreen }) => {
  const [loader, setLoader] = useState(false);

  const { toastSuccess, toastError } = useContext(webContext);

  const helpCenterApi = async (values) => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${helpCenter}`,
        { ...values },
        { headers }
      );
      if (res?.data?.success) {
        setLoader(false);
        toastSuccess(res?.data?.message || "Message Sent Successfull!");
        setScreen(0);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const initialValues = {
    message: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string().required("Field is required!"),
  });

  const onSubmit = (values, { resetForm }) => {
    helpCenterApi(values);
    resetForm();
  };

  return (
    <motion.div
      initial={{ opacity: 0, scaleY: 0.4 }}
      whileInView={{ opacity: 1, scaleY: 1 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      className="help_center"
    >
      <ScreenTitle title="Help Center" clickHandler={() => setScreen(0)} />
      <h6>Please Share Your Problem</h6>     
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <Field
            as="textarea"
            type="text"
            id="message"
            name="message"
            placeholder="Enter Issue Here..."
          />
          <ErrorMessage name="message" component={TextError} />
          <p className="t_14">Our team will reach out to you in 2 business days</p>
          {loader ? (
            <button type="button" className="secondary_btn">
              Loading...
            </button>
          ) : (
            <button type="submit" className="secondary_btn">
             Submit
            </button>
          )}
        </Form>
      </Formik>
    </motion.div>
  );
};

export default HelpCenter;
