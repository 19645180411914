import "./BlogLanding.scss";
import { motion } from "framer-motion";

const BlogLanding = () => {
  return (
    <section className="blog_landing">
      <div className="content_wrap p_t p_b">
        <div className="title">
          <motion.h1
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className="big"
          >
            Resources
          </motion.h1>
       
        </div>
      </div>
    </section>
  );
};

export default BlogLanding;
