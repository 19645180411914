import { FaVideo } from "react-icons/fa6";
import { baseUrl } from "../../../utils/apiData";
import { useEffect, useState } from "react";

const VideoSec = ({ videoUrl, thumbNail }) => {


  return (
    <div className="video_sec">
      <h5 style={{ marginBottom: "16px" }}>
        <FaVideo /> Video
      </h5>

      <video
        controls
        style={{ width: "100%" }}
        download="off"
        id="videoPlayer"
        postre={thumbNail}
        controlsList="nodownload"
      >
        <source src={videoUrl} 
        // type="video/mp4"
         />
      </video>
    </div>
  );
};

export default VideoSec;
