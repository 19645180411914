import NormalButton from "../../../components/normalButton/NormalButton";
import TextPill from "../../../components/textPill/TextPill";
import "./LatestBlogs.scss";
import { images } from "../../../utils/images";
import LatestPopularTab from "./latestPopularTab/LatestPopularTab";
import { Fragment, useState } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const LatestBlogs = ({ blogs, latestBlog }) => {
  const [tab, setTab] = useState("popular");

  const navigate = useNavigate();

  const pill = {
    popular: "#206583",
    latest: "#49BDB6",
  };

  return (
    <section className="latest_blogs p_b">
      <div className="content_wrap">
        <motion.h3
          initial={{ opacity: 0, translateX: 100 }}
          whileInView={{ opacity: 1, translateX: 0 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          Latest
        </motion.h3>
        <div className="bi_grid">
         
            <Fragment>
              <div className="inner_grid">
                <div className="text_wrap">
              
              <div className="pill_wraper">
                  <TextPill title={latestBlog?.name} theme="#206583" />
                  </div>
                  <motion.h3
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.15,
                    }}
                  >
                    {latestBlog?.subtitle}
                  </motion.h3>
                  <NormalButton
                    title="Read Article"
                    type="bordered"
                    clickHandler={() => navigate(`/blogs/${latestBlog?.slug}`)}
                    delay={0.3}
                  />
                </div>
                <div className="img_wrap">
                  <motion.img
                    initial={{ opacity: 0, scale: 0.5 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                    src={latestBlog.image}
                    alt="blog"
                  />
                </div>
              </div>
            </Fragment>
         

          <div className="popular_latest">
            <LatestPopularTab tab={tab} setTab={setTab} />

            {blogs.filter((ele) => ele.category === tab)?.length > 0 ? (
              blogs
                .filter((ele) => ele.category === tab)
                ?.map((data) => {
                  return (
                    <div
                      className="blog_card"
                      key={data._id}
                      onClick={() => navigate(`/blogs/${data?.slug}`)}
                    >
                      <div className="pill_wraper">
                      <TextPill
                        title={data.category}
                        theme={pill[`${data.category}`]}
                      />
                      </div>
                      <p className="text">
                        {data.name} <HiArrowNarrowRight />
                      </p>
                    </div>
                  );
                })
            ) : (
              <p className="text-center my-5">Nothing to see...</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestBlogs;
