import "./Footer.scss";
import { BsTwitterX } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { GrApple } from "react-icons/gr";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <section className="footer">
        <div className="content_wrap">
          <div className="link_grid">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.15 }}
              className="link_col"
            >
              <p>Inauditus</p>
              <NavLink to="/">Home</NavLink>
              <NavLink to="/offerings">Offerings</NavLink>
              <NavLink to="/blogs">Resources</NavLink>
              <NavLink to="/about">About</NavLink>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.3 }}
              className="link_col"
            >
              <p>Offerings</p>
              <NavLink to="/offerings/intervention">Intervention</NavLink>
              <NavLink to="/offerings/coaching">Coaching</NavLink>
              <NavLink to="/offerings/consultancy">Consultancy</NavLink>
              <NavLink to="/offerings/course">Courses</NavLink>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.45 }}
              className="link_col"
            >
              <p>Actions</p>
              <NavLink to="/signin">Sign In</NavLink>
              <NavLink to="/contact">Contact</NavLink>
            </motion.div>

            <div className="app_cta">
              <button type="button">
                <IoLogoGooglePlaystore />
                <div className="text">
                  <p className="t_12">Get It On</p>
                  <h6>Google Play</h6>
                </div>
              </button>
              <button type="button">
                <GrApple />
                <div className="text">
                  <p className="t_12">Download On The</p>
                  <h6>App Store</h6>
                </div>
              </button>
            </div>
          </div>
          <div className="bottom_line">
            <motion.p
              initial={{ opacity: 0, translateX: 50 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.1 }}
            >
              © 2024 Supagrow. All rights reserved.
            </motion.p>

            <div className="center">
              <NavLink to="/privacy_policy">Privacy Policy</NavLink>
            </div>

            <div className="social">
              <motion.a
                initial={{ opacity: 0, translateX: 50 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut", delay: 0.3 }}
                href="https://in.linkedin.com/company/inauditus-intervention"
                target="_blank"
              >
                <FaLinkedinIn />
              </motion.a>
              <motion.a
                initial={{ opacity: 0, translateX: 50 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut", delay: 0.4 }}
                href="https://x.com/InauditusI"
                target="_blank"
              >
                <BsTwitterX />
              </motion.a>
              <motion.a
                initial={{ opacity: 0, translateX: 50 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut", delay: 0.5 }}
                href="https://www.youtube.com/channel/UCRlFa79ptOh83EgygGrY5IA"
                target="_blank"
              >
                <FaYoutube />
              </motion.a>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
