import "./Offering.scss";
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import { GiTeacher } from "react-icons/gi";

import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { images } from "../../utils/images";

const Offering = ({home}) => {
  const navigate = useNavigate();

   return (
    <section className="offering p_t">
      <div className="content_wrap">
        <motion.h1
          initial={{ opacity: 0, translateY: 60 }}
          whileInView={{ opacity: 1, translateY: 0 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
          className="p_b"
        >
          {home?.offring_title || "-"}
        </motion.h1>
        <div className="offer_grid">
          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            onClick={() => navigate("/offerings/intervention")}
            className="offer_card a"
          >
            <div className="icon_box">
              <img src={home?.offer_1_image} alt={home?.offer_1_image_alt} />
            </div>
            <h3>{home?.offer_1_title || "-"}</h3>
            <div className="img_wrap"></div>
            <p>
           {home?.offer_1_description || "-"}
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.1 }}
            onClick={() => navigate("/offerings/coaching")}
            className="offer_card b"
          >
            <div className="icon_box">
            <img src={home?.offer_2_image} alt={home?.offer_2_image_alt} />
            </div>
            <h3>{home?.offer_2_title || "-"}</h3>
            <div className="img_wrap"></div>
            <p>
           {home?.offer_2_description || "-"}
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            onClick={() => navigate("/offerings/consultancy")}
            className="offer_card c"
          >
            <div className="icon_box">
            <img src={home?.offer_3_image} alt={home?.offer_3_image_alt} />
            </div>
            <h3>{home?.offer_3_title || "-"}</h3>
            <div className="img_wrap"></div>
            <p>
           {home?.offer_3_description || "-"}
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.3 }}
            onClick={() => navigate("/offerings/courses")}
            className="offer_card d"
          >
            <div className="icon_box">
            <img src={home?.offer_4_image} alt={home?.offer_4_image_alt} />
            </div>
            <h3>{home?.offer_4_title || "-"}</h3>
            <div className="img_wrap"></div>
            <p>
           {home?.offer_4_description || "-"}
            </p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Offering;
