import { useNavigate } from "react-router-dom";
import TextPill from "../../../../components/textPill/TextPill";
import { images } from "../../../../utils/images";
import "./BlogCard.scss";

const BlogCard = ({ data }) => {
  const pillTheme = {
    Article: "#206583",
    Webinar: "#49BDB6",
    NewsLetter: "#6B7280",
  };

  const navigate = useNavigate();

  return (
    <div className="blog_card" onClick={() => navigate(`/blogs/${data?.slug}`)}>
      <div className="img_wrap">
        <img src={data?.image} alt="blog" />
      </div>
      <p className="text">{data.subtitle || "-"}</p>
      <h4>{data?.name || "-"}</h4>
    </div>
  );
};

export default BlogCard;
