import "./LmsSideBar.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink } from "react-router-dom";
import { images } from "../../../../utils/images";

function LmsSideBar({ show, handleClose }) {
  return (
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <img src={images.logo} alt="logo" />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="side_links">
          <NavLink to="/auth/home">Home</NavLink>
          <NavLink to="/auth/courses">Courses</NavLink>
          <NavLink to="/auth/bookmark">Bookmarks</NavLink>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default LmsSideBar;
