import "./ConsultKeyAreas.scss";
import { images } from "../../../utils/images";
import ScrollTrigger from "react-scroll-trigger";
import { Fragment, useState } from "react";
import { motion } from "framer-motion";

const ConsultKeyAreas = ({ data }) => {
  const [num, setNum] = useState(0);

  const para1 = data?.section_2_description || "-";
  const para2 = data?.section_3_description || "-";
  const para3 = data?.section_4_description || "-";
  const para4 = data?.section_5_description || "-";

  const title1 = data?.section_2_title;
  const title2 = data?.section_3_title;
  const title3 = data?.section_4_title;
  const title4 = data?.section_5_title;

  const img1 = data?.section_2_image;
  const img2 = data?.section_3_image;
  const img3 = data?.section_4_image;
  const img4 = data?.section_5_image;

  const alt1 = data?.section_2_image_alt;

  const alt2 = data?.section_3_image_alt;
  const alt3 = data?.section_4_image_alt;

  const alt4 = data?.section_5_image_alt;

  return (
    <section className="consult_key_areas p_b">
      {/* width > tab */}
      <div className="content_wrap tab">
        <motion.h3
          initial={{ opacity: 0, translateY: 60 }}
          whileInView={{ opacity: 1, translateY: 0 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          {data?.section_1_title || "-"}
        </motion.h3>
        <div className="bi_grid">
          <div className="left_text">
            <div className="line"></div>
            <div className="sticky_line"></div>

            <div className="content a">
              <ScrollTrigger onEnter={() => setNum(0)}>
                <motion.h3
                  initial={{ opacity: 0, translateY: 60 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                  viewport={{ once: true }}
                >
                  {title1}
                </motion.h3>
                <motion.p
                  initial={{ opacity: 0, translateY: 60 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
                  viewport={{ once: true }}
                  className="t_20"
                >
                  {para1}
                </motion.p>
              </ScrollTrigger>
            </div>

            <div className="content b">
              <ScrollTrigger onEnter={() => setNum(1)}>
                <motion.h3
                  initial={{ opacity: 0, translateY: 60 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                  viewport={{ once: true }}
                >
                  {title2}
                </motion.h3>
                <motion.p
                  initial={{ opacity: 0, translateY: 60 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
                  viewport={{ once: true }}
                  className="t_20"
                >
                  {para2}
                </motion.p>
              </ScrollTrigger>
            </div>

            <div className="content c">
              <ScrollTrigger onEnter={() => setNum(2)}>
                <motion.h3
                  initial={{ opacity: 0, translateY: 60 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                  viewport={{ once: true }}
                >
                  {title3}
                </motion.h3>
                <motion.p
                  initial={{ opacity: 0, translateY: 60 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
                  viewport={{ once: true }}
                  className="t_20"
                >
                  {para3}
                </motion.p>
              </ScrollTrigger>
            </div>

            <div className="content d">
              <ScrollTrigger onEnter={() => setNum(3)}>
                <motion.h3
                  initial={{ opacity: 0, translateY: 60 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                  viewport={{ once: true }}
                >
                  {title4}
                </motion.h3>
                <motion.p
                  initial={{ opacity: 0, translateY: 60 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
                  viewport={{ once: true }}
                  className="t_20"
                >
                  {para4}
                </motion.p>
              </ScrollTrigger>
            </div>
          </div>
          <div className="right_boxes">
            <div className={`box a ${num === 0 ? "active" : ""}`}>
              <img src={img1} alt={alt1} />
            </div>
            <div className={`box b ${num === 1 ? "active" : ""}`}>
              <img src={img2} alt={alt2} />
            </div>
            <div className={`box c ${num === 2 ? "active" : ""}`}>
              <img src={img3} alt={alt3} />
            </div>
            <div className={`box d ${num === 3 ? "active" : ""}`}>
              <img src={img4} alt={alt4} />
            </div>
          </div>
        </div>
      </div>

      {/* width < tab */}
      <div className="content_wrap small">
        <div className="small_card">
          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className="box_card a"
          >
            <img src={img1} alt={alt1} />
          </motion.div>
          <motion.h3
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            viewport={{ once: true }}
          >
            {title1}
          </motion.h3>
          <motion.p
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.1 }}
          >
            {para1}
          </motion.p>
        </div>

        <div className="small_card">
          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className="box_card b"
          >
            <img src={img2} alt={alt2} />
          </motion.div>
          <motion.h3
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            viewport={{ once: true }}
          >
            {title2}
          </motion.h3>
          <motion.p
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.1 }}
          >
            {para2}
          </motion.p>
        </div>

        <div className="small_card">
          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className="box_card c"
          >
            <img src={img3} alt={alt3} />
          </motion.div>
          <motion.h3
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            viewport={{ once: true }}
          >
            {title3}
          </motion.h3>
          <motion.p
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.1 }}
          >
            {para3}
          </motion.p>
        </div>

        <div className="small_card">
          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className="box_card d"
          >
            <img src={img4} alt={alt4} />
          </motion.div>
          <motion.h3
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            viewport={{ once: true }}
          >
            {title4}
          </motion.h3>
          <motion.p
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.1 }}
          >
            {para4}
          </motion.p>
        </div>
      </div>
    </section>
  );
};
export default ConsultKeyAreas;
