import PrimaryBtn from "../primaryBtn/PrimaryBtn";
import "./CTA.scss";
import { motion } from "framer-motion";

const CTA = ({title, btnText, clickHandler}) => {
    return(
        <section className="cta p_t p_b">
        <div className="content_wrap">
          <motion.div 
              initial={{ opacity: 0, translateY: 60, scale:0.8 }}
              whileInView={{ opacity: 1, translateY: 0, scale:1 }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
          className="content_box p_t p_b">
            <motion.h2
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
             {title}
            </motion.h2>
            <PrimaryBtn
              title={btnText}
              clickHandler={clickHandler}
              delay={0.2}
            />
          </motion.div>
        </div>
      </section>
    )
}

export default CTA;