import { useNavigate, useParams } from "react-router-dom";
import LmsLayout from "../lmsComponents/lmsLayout/LmsLayout";
import "./ChapterDetails.scss";
import axios from "axios";
import { baseUrl, chapterById, chapterComplate } from "../../utils/apiData";
import { useEffect, useState, Fragment, useContext } from "react";
import PageLoader from "../lmsComponents/pageLoader/PageLoader";
import { PiBookOpenText } from "react-icons/pi";
import { IoMdArrowBack } from "react-icons/io";
import { IoMdArrowForward } from "react-icons/io";

import VideoSec from "./videoSec/VideoSec";
import SectionSec from "./sectionSec/SectionSec";
import DescriptionSec from "./descriptionSec/DescriptionSec";
import AssignmentSec from "./assignmentSec/AssignmentSec";
import QuizSec from "./quizeSec/QuizeSec";
import { webContext } from "../../webContext/WebContext";

const ChapterDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toastSuccess, logOutApi, setAsModule, assSubEffect } = useContext(webContext);

  const [chapterName, setChapterName] = useState("");
  const [chapters, setChapters] = useState();
  const [loader, setLoader] = useState(false);
  const [completeLoader, setCompleteLoader] = useState(false);
  const [isQuiz, setIsQuiz] = useState(false);
  const [isQuizUploaded, setIsQuizUploaded] = useState(false);
  const [assignmentUpload, setAssignmentUpload] = useState(false);

  const [isLast, setIsLast] = useState(false);
  const [courseId, setCourseId] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);

  const getChapterDetails = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${chapterById}`,
        { chapter_id: id },
        { headers }
      );
      if (res?.data?.success) {
        setLoader(false);
        setIsLast(res?.data?.data?.isLastChapter);
        setChapterName(res?.data?.data?.populatedChapter?.chapter);
        setIsCompleted(res?.data?.data?.populatedChapter?.completed);
        setCourseId(res?.data?.data?.moduless?.course);
        setModuleId(res?.data?.data?.populatedChapter?.module);
        setIsQuiz(res?.data?.data?.populatedChapter?.is_quizz);
        setIsQuizUploaded(res?.data?.data?.populatedChapter?.is_quizz_uploaded);
        setChapters(res?.data?.data?.populatedChapter?.chapterinputs);
        setAssignmentUpload(res?.data?.data?.populatedChapter?.is_assignment_uploaded);
        console.log(res?.data?.data?.populatedChapter);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      if (error?.response?.status == 401) {
        logOutApi();
      }
    }
  };

  useEffect(() => {
    setAsModule(false);
  }, []);

  useEffect(() => {
    getChapterDetails();
  }, [assSubEffect]);

  const goUp = () => {
    if (isLast) {
      setAsModule(true);
      navigate(`/auth/course/${courseId}`);
    } else {
      navigate(`/auth/lessons/${moduleId}`);
    }
  };

  // Mark as completed

  const completeChapterApi = async () => {
    setCompleteLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${chapterComplate}`,
        { chapterId: id },
        { headers }
      );

      if (res?.data?.success) {
        setCompleteLoader(false);
        toastSuccess(res?.data?.message);

        goUp();
      }
    } catch (error) {
      setCompleteLoader(false);
      console.log(error);
    }
  };

  return (
    <LmsLayout>
      <section className="chapter_detail">
        <div className="content_wrap">
          <div className="chapter_view">
            {loader ? (
              <PageLoader />
            ) : chapters?.length > 0 ? (
              <Fragment>
                <div className="chapter_title">
                  <h4>
                    <PiBookOpenText /> {chapterName || "-"}
                  </h4>
                </div>
                <div className="h_line"></div>

                {chapters?.map((data, i) => {
                  return (
                    <Fragment key={i}>
                      {data?.field === "section" && (
                        <SectionSec
                          title={data?.section_title}
                          para={data?.section_description}
                        />
                      )}

                      {data?.field === "assignment" && (
                        <AssignmentSec info={data} assignmentUpload={assignmentUpload}  />
                      )}

                      {data?.field === "description" && (
                        <DescriptionSec para={data?.description} />
                      )}

                      {data?.field === "video" && (
                        <VideoSec
                          videoUrl={data?.video}
                          thumbNail={data?.video_thumbnail}
                        />
                      )}
                    </Fragment>
                  );
                })}
                {isQuiz && <QuizSec id={id} isQuizUploaded={isQuizUploaded} />}
              </Fragment>
            ) : (
              <p className="text-center mt-5">Data not found...</p>
            )}
          </div>

          {!loader && (
            <div className="mark_btn_wrap">
              <button
                type="button"
                className="secondary_btn"
                onClick={isCompleted ? goUp : completeChapterApi}
              >
                {completeLoader
                  ? "Loading..."
                  : isCompleted
                  ? "Next"
                  : "Complete & Continue"}
              </button>
            </div>
          )}
        </div>
      </section>
    </LmsLayout>
  );
};

export default ChapterDetails;
