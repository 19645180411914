import WebLayout from "../../components/weblayout/WebLayout";
import ContactForm from "./contactForm/ContactForm";

const Contact = () => {
  return (
    <WebLayout>
      <ContactForm />
    </WebLayout>
  );
};
export default Contact;
