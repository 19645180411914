import "./TextPill.scss";
import {motion} from "framer-motion";

const TextPill = ({title, theme, delay, widthLimit}) => {
    return(
        <motion.div 
        initial={{ opacity: 0, scale:0 }}
        whileInView={{ opacity: 1, scale:1 }}
        transition={{ duration: 0.6, ease: "easeInOut", delay:delay }}
        className="text_pill"
        style={{background:`${theme}`, maxWidth:`${widthLimit ? widthLimit : '300'}px`}}
        >
            <p>{title}</p>
        </motion.div>
    )
}

export default TextPill;