import ScreenTitle from "../screenTitle/ScreenTitle";
import "./History.scss";
import { motion } from "framer-motion";
import { images } from "../../../../../utils/images";
import { FaUser } from "react-icons/fa";
import { baseUrl, purchasedHistory } from "../../../../../utils/apiData";
import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";


const History = ({ setScreen }) => {


  const [history, setHistory] = useState([]);
  const [loader, setLoader] = useState(false);

  const getPurchaseHistoryApi = async () => {
    setLoader(true);

    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.get(`${baseUrl}/${purchasedHistory}`, {
        headers,
      });
      if (res?.data?.success) {
        setLoader(false);
        setHistory(res?.data?.data);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getPurchaseHistoryApi();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, scaleY: 0.4 }}
      whileInView={{ opacity: 1, scaleY: 1 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      className="history_wraper"
    >
      <ScreenTitle title="History" clickHandler={() => setScreen(0)} />
      <div className="history_list">
        {
          loader ? <div className="loader_card">
            <Spinner animation="border" role="status"></Spinner>
          </div> : 
          history?.length > 0 ?
          history.map((data) => {
            return (
              <div className="history_card" key={data.id}>
                <div className="img_wrap">
                  <img src={data?.course_id?.image} alt="history" />
                </div>
                <div className="text_wrap">
                  <h6>{data?.course_id?.name || "-"}</h6>
                  <div className="name_wrap">
                    <FaUser />
                    <p>{data?.course_id?.author_name || "-"}</p>
                  </div>
                  <p>{data?.formatted_course_purchase_date || "-"}</p>
                </div>
              </div>
            );
          }) : <p>No courses purchased…</p>

        }
        
      </div>
    </motion.div>
  );
};

export default History;
