import { images } from "../../../utils/images";
import "./GetApp.scss";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { GrApple } from "react-icons/gr";
import { motion } from "framer-motion";

const GetApp = ({ home }) => {
  return (
    <section className="get_app">
      <div className="content_wrap">
        <div className="bi_grid">
          <div className="img_wrap">
            <motion.img
              initial={{ opacity: 0, scale: 0.4 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              src={home?.section_6_image}
              alt={home?.section_6_image_alt}
            />
          </div>
          <div className="text_btn">
            <motion.h2
              initial={{ opacity: 0, translateY: 48 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              {home?.section_6_title || "-"}
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, translateY: 48 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            >
              {home?.section_6_description || "-"}
            </motion.p>
            <div className="app_cta_btns">
              <motion.a
                initial={{ opacity: 0, translateX: 48 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut", delay: 0.4 }}
                target="_blank"
                href={home?.section_6_apple_link}
              >
                <IoLogoGooglePlaystore />
                <div className="text">
                  <p className="t_12">Get It On</p>
                  <h6>Google Play</h6>
                </div>
              </motion.a>
              <motion.a
                initial={{ opacity: 0, translateX: 48 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut", delay: 0.6 }}
                target="_blank"
                href={home?.section_6_google_play_link}
              >
                <GrApple />
                <div className="text">
                  <p className="t_12">Download On The</p>
                  <h6>App Store</h6>
                </div>
              </motion.a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetApp;
