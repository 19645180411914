import "./ErrorSec.scss";
import { images } from "../../../utils/images";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { useNavigate } from "react-router-dom";

const ErrorSec = () => {
  const navigate = useNavigate();

  return (
    <section className="error_sec">
      <div className="content_wrap p_t p_b">
        <div className="error_card">
          <img src={images.fnf} alt="error" />
          <h3>Something’s Not Right.</h3>
          <p>We Can’t Find The Page Your Are Looking For.</p>
          <PrimaryBtn title="Home" clickHandler={() => navigate("/")} />
        </div>
      </div>
    </section>
  );
};

export default ErrorSec;
