import "./SignUp.scss";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { images } from "../../utils/images";
import { motion } from "framer-motion";
import TextError from "../../components/textError/TextError";
import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import WebLayout from "../../components/weblayout/WebLayout";
import { NavLink } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa";
import countries from "../../utils/countries";
import OrganisationField from "./organisationField/OrganisationField";
import { baseUrl, userRegistration } from "../../utils/apiData";
import { webContext } from "../../webContext/WebContext";
import axios from "axios";

const SignUp = () => {
  const { toastError, toastSuccess } =
    useContext(webContext);

  const [loader, setLoader] = useState(false);
  const [type, setType] = useState(false);

  const initialValues = {
    name: "",
    mobile: "",
    email: "",
    roll: "",
    org_id: "",
    country: "",
    password: "",
  };
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    name: Yup.string().required("Field required"),
    mobile: Yup.string().required("Field required"),
    roll: Yup.string().required("Field required"),
    country: Yup.string().required("Field required"),
    email: Yup.string()
      .email("invalid email format")
      .required("Email Required"),
    password: Yup.string().required("Field required"),
  });

  const registrationApi = async (values) => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${userRegistration}`, {
        ...values,
      });

      if (res?.data?.success) {
        setLoader(false);
        toastSuccess(res?.data?.message || "Registration Success!");
        navigate("/signin");
      } else {
        toastError("Something went wrong!");
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toastError(error?.response?.data?.message || "Something went wrong!");
        setLoader(false);
      }
    }
  };

  const onSubmit = (values, { resetForm }) => {
    registrationApi(values);
    resetForm();
  };

  return (
    <WebLayout>
      <section className="sign_up p_b">
        <div className="content_wrap">
        <div className="bi_sec">
          <div className="img_wraper p_t p_b">
            <motion.img
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 0.1 }}
              src={images.loginBanner}
              alt="Illustration of a hand holding a smartphone with an interface displaying user profile and social media icons hovering above the screen."
            />
          </div>
          <div className="form_wraper p_b p_t">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values }) => (
                <Form className="signup">
                  <div className="title">
                    <motion.h3
                      initial={{ opacity: 0, translateY: 60 }}
                      whileInView={{ opacity: 1, translateY: 0 }}
                      transition={{ duration: 0.6, ease: "easeInOut" }}
                    >
                      Create an <span>Inauditus</span> Account
                    </motion.h3>
                  </div>

                  <motion.div
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.1,
                    }}
                    className="field_wrap"
                  >
                    <label>Name</label>
                    <Field
                      type="text"
                      placeholder="Name"
                      id="name"
                      name="name"
                      autoComplete="off"
                    />
                    <ErrorMessage name="name" component={TextError} />
                  </motion.div>

                  <div className="bi_field">
                    <motion.div
                      initial={{ opacity: 0, translateY: 60 }}
                      whileInView={{ opacity: 1, translateY: 0 }}
                      transition={{
                        duration: 0.6,
                        ease: "easeInOut",
                        delay: 0.3,
                      }}
                      className="field_wrap"
                    >
                      <label>Mobile</label>
                      <Field
                        type="number"
                        placeholder="+99 9999 9999 9999"
                        id="mobile"
                        name="mobile"
                        autoComplete="off"
                      />
                      <ErrorMessage name="mobile" component={TextError} />
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, translateY: 60 }}
                      whileInView={{ opacity: 1, translateY: 0 }}
                      transition={{
                        duration: 0.6,
                        ease: "easeInOut",
                        delay: 0.4,
                      }}
                      className="field_wrap"
                    >
                      <label>Email</label>
                      <Field
                        type="text"
                        placeholder="e.g. email@gmail.com"
                        id="email"
                        name="email"
                        autoComplete="off"
                      />
                      <ErrorMessage name="email" component={TextError} />
                    </motion.div>
                  </div>

                  <div className="bi_field">
                    <motion.div
                      initial={{ opacity: 0, translateY: 60 }}
                      whileInView={{ opacity: 1, translateY: 0 }}
                      transition={{
                        duration: 0.6,
                        ease: "easeInOut",
                        delay: 0.5,
                      }}
                      className="field_wrap"
                    >
                      <label>Role</label>
                      <Field as="select" id="roll" name="roll">
                        <option value="" disabled>
                          - Select -
                        </option>
                        <option value="user">User</option>
                        <option value="Universities">Universities</option>
                        <option value="Corporate">Corporate</option>
                      </Field>
                      <ErrorMessage name="roll" component={TextError} />
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, translateY: 60 }}
                      whileInView={{ opacity: 1, translateY: 0 }}
                      transition={{
                        duration: 0.6,
                        ease: "easeInOut",
                        delay: 0.5,
                      }}
                      className="field_wrap"
                    >
                      <label>Country</label>
                      <Field as="select" id="country" name="country">
                        <option value="" disabled>
                          - Select -
                        </option>
                        {countries?.map((data) => {
                          return (
                            <option value={data.name} key={data.name}>
                              {data.name}
                            </option>
                          );
                        })}
                        <option value="user">User</option>
                        <option value="Universities">Universities</option>
                        <option value="Corporate">Corporate</option>
                      </Field>
                      <ErrorMessage name="country" component={TextError} />
                    </motion.div>
                  </div>

                  {["Universities", "Corporate"].includes(values.roll) && (
                    <OrganisationField type={values.roll} />
                  )}

                  <motion.div
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.6,
                    }}
                    className="field_wrap"
                  >
                    <label>Password</label>
                    <div className="password_wrap">
                      <Field
                        type={type ? "text" : "password"}
                        placeholder="Password"
                        id="password"
                        name="password"
                        autoComplete="off"
                      />
                      <button type="button" onClick={() => setType(!type)}>
                        {type ? <FaEyeSlash /> : <IoEyeSharp />}
                      </button>
                    </div>
                    <ErrorMessage name="password" component={TextError} />
                  </motion.div>
                  <motion.p
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.7,
                    }}
                    className="suggestion"
                  >
                    Existing user? please{" "}
                    <NavLink to="/signin">Log In</NavLink>
                  </motion.p>

                  <motion.button
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.8,
                    }}
                    type="submit"
                    className="submit_btn"
                  >
                    {loader ? "Loading..." : "Submit"}
                  </motion.button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        </div>
      </section>
    </WebLayout>
  );
};

export default SignUp;
