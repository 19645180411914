import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";




import { GoogleOAuthProvider } from "@react-oauth/google";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Router>
      <Provider store={store}>
        <GoogleOAuthProvider clientId="993543141294-i833da20p1j49qk7dsu2n5vt3i4rap48.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </Provider>
    </Router>
  /* </React.StrictMode> */
);
reportWebVitals();
