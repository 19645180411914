import "./LogoutPop.scss";
import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { webContext } from "../../../../../webContext/WebContext";

const LogoutPop = ({ setScreen }) => {
  const { logOutApi } = useContext(webContext);
  const [loader, setLoader] = useState(false);

  const logoutHandler = () => {
    setLoader(true);
    logOutApi();
  };

  return (
    <div className="log_out">
      <motion.div
        initial={{ opacity: 0, scaleY: 0 }}
        whileInView={{ opacity: 1, scaleY: 1 }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
        className="logout_modal"
      >
        <div className="line"></div>
        <h4>Logout</h4>
        <p>Are you sure you want to leave?</p>
        <button type="button" className="secondary_btn" onClick={logoutHandler}>
          {loader ? "Loading..." : "Yes"}
        </button>
        <button
          type="button"
          className="secondary_btn bordered"
          onClick={() => setScreen(0)}
        >
          Cancel
        </button>
      </motion.div>
    </div>
  );
};
export default LogoutPop;
