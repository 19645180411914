import NormalButton from "../../../components/normalButton/NormalButton";
import { baseUrl } from "../../../utils/apiData";
import "./LmsHomeClasses.scss";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const LmsHomeClasses = ({ homeCta }) => {
  const navigate = useNavigate();
  return (
    <section className="classes_banner p_t">
      <div className="content_wrap">
        <motion.div
          initial={{ translateY: 48, scaleX: 0.8, opacity: 0 }}
          whileInView={{ translateY: 0, scaleX: 1, opacity: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
          className="banner_wrap"
        >
          <div className="left">
            <h2>{homeCta?.title || "-"}</h2>           
            <NormalButton
              title={homeCta?.button_name || "-"}
              type="button"
              clickHandler={() => navigate("/auth/courses")}
            />
          </div>
          <div className="sketch">
            <img src={homeCta?.image} alt="llustration of a person sitting at a desk with a computer and charts on the wall behind." />
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default LmsHomeClasses;
