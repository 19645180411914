import axios from "axios";
import PrimaryBtn from "../primaryBtn/PrimaryBtn";
import "./EmailSubscription.scss";
import { motion } from "framer-motion";
import { baseUrl, emailSubscription } from "../../utils/apiData";
import { useContext, useState } from "react";
import { webContext } from "../../webContext/WebContext";

const EmailSubscription = () => {
  const [emailError, setEmailError] = useState("");
  const [mail, setMail] = useState("");
  const [loader, setLoader] = useState("");

  const { toastSuccess, toastError } = useContext(webContext);

  const emailSubscribeApi = async () => {
    const Emailregwx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail === 0) {
      setEmailError("Field is required");
    } else if (!Emailregwx.test(mail)) {
      setEmailError("Invalid Email!");
    } else {
      setLoader(true);
      try {
        const res = await axios.post(`${baseUrl}/${emailSubscription}`, {
          email: mail,
        });
  
        if (res?.data?.success) {
          toastSuccess(res?.data?.message || "Subscription Successful!");
          setMail("");
          setEmailError("");
          setLoader(false);
        }
      } catch (error) {
        console.log(error);
        toastError(error?.response?.data?.message || "Something went wrong!");
        setLoader(false);
      }
    }
   
  };

  const inputHandler = (e) => {
    const Emailregwx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (e.target.value.length === 0) {
      setEmailError("Field is required");
    } else if (!Emailregwx.test(e.target.value)) {
      setEmailError("Invalid Email!");
    } else {
      setEmailError("");
    }

    setMail(e.target.value);
  };

  const focusHandler = (e) => {
    setEmailError("");
  };

  const blurHandler = (e) => {
    if (e.target.value.length === 0) {
      setEmailError("Field is required");
    } else {
      setEmailError("");
    }
  };

  return (
    <section className="email_sub">
      <div className="content_wrap">
        <div className="bi_grid">
          <div className="text">
            <motion.h3
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              Let’s take this to your inbox
            </motion.h3>
            <motion.p
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
              className="t_18"
            >
              Subscribe for updates on our offerings, client success stories,
              and the latest insights.
            </motion.p>
          </div>
          <div className="email_box">
            <div className="input_line">
              <motion.input
                initial={{ scale: 0, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                transition={{
                  duration: 0.6,
                  ease: "easeInOut",
                }}
                type="email"
                placeholder="Email address"
                value={mail}
                onChange={inputHandler}
                onFocus={focusHandler}
                onBlur={blurHandler}
              />
              <PrimaryBtn
                title={loader ? "Loading..." : "I'm In"}
                clickHandler={emailSubscribeApi}
                delay={0.4}
                
              />
            </div>
            {emailError && <p className="error">{emailError}</p>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmailSubscription;
