import "./ForgotPassword.scss";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { images } from "../../utils/images";
import { motion } from "framer-motion";
import TextError from "../../components/textError/TextError";
import { useContext, useState } from "react";
import WebLayout from "../../components/weblayout/WebLayout";
import { useNavigate } from "react-router-dom";
import { baseUrl, forgotPassword } from "../../utils/apiData";
import axios from "axios";
import { webContext } from "../../webContext/WebContext";
import SuccessModel from "../../components/successModal/SuccessModel";
import VerifyOtpModel from "./verifyOTpModel/VerifyOtpModel";
import CreateNewPassword from "./createNewPassword/CreateNewPassword";

const ForgotPassword = () => {
  const { toastError, toastSuccess } = useContext(webContext);
  const [loader, setLoader] = useState(false);

  const [otpModal, setOtpModal] = useState(false);
  const [passwordModel, setPasswordModel] = useState(false);

  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("invalid email format")
      .required("Email Required"),
  });

  const forgotPasswordApi = async (values) => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${forgotPassword}`, {
        ...values,
      });
      if (res?.data?.success) {
        toastSuccess(res?.data?.message);
        setLoader(false);
        setOtpModal(true);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const onSubmit = (values, { resetForm }) => {
    forgotPasswordApi(values);
    setEmail(values.email);
  };

  return (
    <WebLayout>
      <section className="forgot_password p_b">
        <div className="content_wrap">
          <div className="bi_sec">
            <div className="img_wraper p_t p_b">
              <motion.img
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1, ease: "easeInOut", delay: 0.1 }}
                src={images.loginBanner}
                alt="Illustration of a hand holding a smartphone with an interface displaying user profile and social media icons hovering above the screen."
              />
            </div>
            <div className="form_wraper p_t p_b">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                <Form>
                  <div className="title">
                    <motion.h3
                      initial={{ opacity: 0, translateY: 60 }}
                      whileInView={{ opacity: 1, translateY: 0 }}
                      transition={{ duration: 0.6, ease: "easeInOut" }}
                    >
                      Password Recovery
                    </motion.h3>
                    <motion.p
                      initial={{ opacity: 0, translateY: 60 }}
                      whileInView={{ opacity: 1, translateY: 0 }}
                      transition={{
                        duration: 0.6,
                        ease: "easeInOut",
                        delay: 0.2,
                      }}
                    >
                      Enter your registered email address
                    </motion.p>
                  </div>

                  <motion.div
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.1,
                    }}
                    className="field_wrap"
                  >
                    <label>Email</label>
                    <Field
                      type="text"
                      placeholder="e.g. email@gmail.com"
                      id="email"
                      name="email"
                      autoComplete="off"
                    />
                    <ErrorMessage name="email" component={TextError} />
                  </motion.div>

                  <motion.button
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.4,
                    }}
                    type="submit"
                    className="submit_btn"
                  >
                    {loader ? "Loading..." : "Email Me"}
                  </motion.button>
                </Form>
              </Formik>
            </div>
          </div>
          <SuccessModel
            show={success}
            setSuccess={setSuccess}
            title="Mail sent Successfully!"
          />
        </div>
      </section>

      {/* Verify Otp */}
      <VerifyOtpModel
        show={otpModal}
        closer={() => setOtpModal(false)}
        setPasswordModel={setPasswordModel}
        email={email}
      />

      {/* Set New Password */}
      <CreateNewPassword 
       show={passwordModel}
       closer={() => setPasswordModel(false)}
       email={email}
      />
    </WebLayout>
  );
};

export default ForgotPassword;
