import "./Team.scss";
import { motion } from "framer-motion";
import { images } from "../../../utils/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";

const Team = ({about}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="team p_t p_b">
      <div className="content_wrap">
        <motion.h2
          initial={{ opacity: 0, translateY: 60 }}
          whileInView={{ opacity: 1, translateY: 0 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          {about?.team_title || "-"}
        </motion.h2>
        <motion.h4
          initial={{ opacity: 0, translateY: 60 }}
          whileInView={{ opacity: 1, translateY: 0 }}
          transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
          className="t_18"
        >
       {about?.team_description || "-"}
        </motion.h4>
        <div className="team_grid p_t">
          <div className="team_wrap">
            <img src={about?.team_1_image} alt={about?.team_1_image_alt}  />
            <h3>{about?.team_1_title || "-"}</h3>
            <NavLink to="/ourteam/mahesh_nambiar">Read More</NavLink>
          </div>
          <div className="team_wrap">
            <img src={about?.team_2_image} alt={about?.team_2_image_alt} />
            <h3>{about?.team_2_title || "-"}</h3>
            <NavLink to="/ourteam/purnima_nambiar">Read More</NavLink>
          </div>
        </div>

        <div className="image_slider p_t">
          <Slider {...settings}>

            {
              about?.images?.map((item, j) => {
                return(
                  <div key={j}>
                  <img src={item} alt="gallery" />
                </div>
                )
              })
            }
           
           
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Team;
