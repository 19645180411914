import "./OtpVerify.scss";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { images } from "../../utils/images";
import { motion } from "framer-motion";
import TextError from "../../components/textError/TextError";
import { useContext, useState } from "react";
import WebLayout from "../../components/weblayout/WebLayout";
import { useNavigate } from "react-router-dom";
import { baseUrl, verifyOtp } from "../../utils/apiData";
import axios from "axios";
import { webContext } from "../../webContext/WebContext";
import SuccessModel from "../../components/successModal/SuccessModel";

const OtpVerify = () => {
  const { toastError, toastSuccess } = useContext(webContext);
  const [loader, setLoader] = useState(false);

  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    otp:""
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("invalid email formate!")
      .required("email is required!"),
      otp:Yup.string().required("Field is required!"),
  });

  const verifyOtpAPI = async (values) => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${verifyOtp}`, {
        ...values
      });
      if (res?.data?.success) {
        toastSuccess(res?.data?.message);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const onSubmit = (values, { resetForm }) => {
    verifyOtpAPI(values);
    resetForm();
  };

  return (
    <WebLayout>
      <section className="otp_verify p_b">
        <div className="content_wrap">
          <div className="bi_sec">
            <div className="img_wraper p_t p_b">
              <motion.img
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1, ease: "easeInOut", delay: 0.1 }}
                src={images.loginBanner}
                alt="Illustration of a hand holding a smartphone with an interface displaying user profile and social media icons hovering above the screen."
              />
            </div>
            <div className="form_wraper p_t p_b">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                <Form>
                  <div className="title">
                    <motion.h3
                      initial={{ opacity: 0, translateY: 60 }}
                      whileInView={{ opacity: 1, translateY: 0 }}
                      transition={{ duration: 0.6, ease: "easeInOut" }}
                    >
                      Verify OTP
                    </motion.h3>
                    <motion.p
                      initial={{ opacity: 0, translateY: 60 }}
                      whileInView={{ opacity: 1, translateY: 0 }}
                      transition={{
                        duration: 0.6,
                        ease: "easeInOut",
                        delay: 0.2,
                      }}
                    >
                      Enter your registered email and OTP
                    </motion.p>
                  </div>

                  <motion.div
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.1,
                    }}
                    className="field_wrap"
                  >
                    <label>Email</label>
                    <Field
                      type="text"
                      placeholder="e.g. email@gmail.com"
                      id="email"
                      name="email"
                      autoComplete="off"
                    />
                    <ErrorMessage name="email" component={TextError} />
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.1,
                    }}
                    className="field_wrap"
                  >
                    <label>OTP</label>
                    <Field
                      type="number"
                      placeholder="999999"
                      id="otp"
                      name="otp"
                      autoComplete="off"
                    />
                    <ErrorMessage name="otp" component={TextError} />
                  </motion.div>

                  <motion.button
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.4,
                    }}
                    type="submit"
                    className="normal_btn bordered"
                  >
                    {loader ? "Loading..." : "Verify OTP"}
                  </motion.button>
                </Form>
              </Formik>
            </div>
          </div>
          <SuccessModel
            show={success}
            setSuccess={setSuccess}
            title="Mail sent Successfully!"
          />
        </div>
      </section>
    </WebLayout>
  );
};

export default OtpVerify;
