import { useNavigate } from "react-router-dom";
import CTA from "../../components/cta/CTA";
import Offering from "../../components/offering/Offering";
import WebLayout from "../../components/weblayout/WebLayout";
import DataCounter from "./dataCounter/DataCounter";
import GetApp from "./getApp/GetApp";
import HomeLanding from "./homeLanding/HomeLanding";
import LeftRightSec from "./leftRightsec/LeftRightSec";
import TrustedBy from "./trustedby/TrustedBy";

import PageLoader from "../../lms/lmsComponents/pageLoader/PageLoader";
import { baseUrl, homePage } from "../../utils/apiData";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";

const Home = () => {
  const navigate = useNavigate();

  const [home, setHome] = useState({});
  const [loader, setLoader] = useState(false);

  const homePageApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${homePage}`);
      if (res?.data?.success) {
        setHome(res?.data?.data);
        setLoader(false);        
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    homePageApi();
  }, []);

  return (
    <WebLayout>
      {loader ? (
        <PageLoader />
      ) : (
        <Fragment>
          <HomeLanding home={home} />
          <DataCounter home={home} />
          <LeftRightSec home={home} />
          <GetApp home={home} />
          <TrustedBy home={home} />
          <Offering home={home} />
          <CTA
            title="Ready for the Unprecedented?"
            btnText="Get in Touch"
            clickHandler={() => navigate(`/contact`)}
          />
        </Fragment>
      )}
    </WebLayout>
  );
};
export default Home;
