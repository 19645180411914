import { Fragment, useContext, useState } from "react";
import ScreenTitle from "../screenTitle/ScreenTitle";
import "./DeleteAccountReason.scss";
import { motion } from "framer-motion";


const DeleteAccountReason = ({reason, setReason, setScreen }) => {
  const radioChange = (e) => {
    setReason(e.target.value);
  };
  return (  

        <motion.div
          initial={{ opacity: 0, scaleY: 0.4 }}
          whileInView={{ opacity: 1, scaleY: 1 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
          className="delete_account_reason"
        >
          <ScreenTitle
            title="Delete Account"
            clickHandler={() => setScreen(0)}
          />
          <div className="delete_wraper">
            <div className="check_line">
              <input
                type="radio"
                name="reason"
                value="I couldn’t find what I want."
                onChange={radioChange}
              />
              <p>I couldn’t find what I want.</p>
            </div>
            <div className="check_line">
              <input
                type="radio"
                name="reason"
                value="The app was not what I expected."
                onChange={radioChange}
              />
              <p>The app was not what I expected.</p>
            </div>
            <div className="check_line">
              <input
                type="radio"
                name="reason"
                value="I’m busy at the moment."
                onChange={radioChange}
              />
              <p>I’m busy at the moment.</p>
            </div>
            <div className="check_line">
              <input
                type="radio"
                name="reason"
                value="Other reasons"
                onChange={radioChange}
              />
              <p>Other reasons.</p>
            </div>
          </div>
         
            <button
              type="button"
              className="secondary_btn"
              onClick={() => {
                setScreen(11);
              }}
            >
              Delete
            </button>
         
        </motion.div>
     
  );
};

export default DeleteAccountReason;
