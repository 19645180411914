import { Fragment } from "react";
import "./RelatedBlogs.scss";
import { motion } from "framer-motion";
import BlogCard from "../../blogs/blogsList/blogCard/BlogCard";
import { images } from "../../../utils/images";

const RelatedBlogs = ({ blogs, currentSlug }) => {
  return (
    <section className="related_blogs">
      <div className="content_wrap">
        <div className="title p_b">
          <motion.h2
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            Related Blogs
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
          >
            Every day, the Inauditus Vision becomes reality.
          </motion.p>
        </div>
        {blogs?.filter((ele) => ele.slug !== currentSlug)?.length > 0 ? (
          <div className="blog_grid p_b">
            {blogs
              ?.filter((ele) => ele.slug !== currentSlug)?.slice(0,3)?.map((data) => {
                return (
                  <Fragment key={data._id}>
                    <BlogCard data={data} />
                  </Fragment>
                );
              })}
          </div>
        ) : (
          <p className="text-center p_t p_b">Data not found...</p>
        )}
      </div>
    </section>
  );
};

export default RelatedBlogs;
