
import LmsLayout from "../lmsComponents/lmsLayout/LmsLayout";
import "./PaymentFail.scss";
import { useNavigate } from "react-router-dom";

const PaymentFail = () => {
  const navigate = useNavigate();

  return (
    <LmsLayout>
      <section className="payment_fail">
        <div className="content_wrap">
        
          <h1>Payment Failed!</h1>
          <p>Sorry, Payment get Failed!</p>
          <button
            type="button"
            className="normal_btn bordered"
            onClick={() => navigate("/auth/courses")}
          >
            Courses
          </button>
        </div>
      </section>
    </LmsLayout>
  );
};

export default PaymentFail;
