import LmsLayout from "../lmsComponents/lmsLayout/LmsLayout";
import HomeRecommended from "./homeRecommended/HomeRecommended";
import HomeShareApp from "./homeShareApp/HomeShareApp";
import LmsHomeClasses from "./lmsHomeClasses/LmsHomeClasses";
import LmsHomeSubjects from "./lmsHomeSubjects/LmsHomeSubjects";
import { baseUrl, getHome, lmsHomeCta } from "../../utils/apiData";
import axios from "axios";
import { Fragment, useContext, useEffect, useState } from "react";
import PageLoader from "../lmsComponents/pageLoader/PageLoader";
import { webContext } from "../../webContext/WebContext";

const LmsHome = () => {
  const {
    logOutApi,
    addCartEffect,
    removeCartEffect,
    bookMarkEffect,
    setAsModule,
  } = useContext(webContext);

  const [loader, setLoader] = useState(false);
  const [homeCta, setHomeCta] = useState();
  const [category, setCategory] = useState([]);
  const [courses, setCourses] = useState([]);

  const getHomeApi = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.get(`${baseUrl}/${getHome}`, { headers });
      if (res?.data?.success) {
        setLoader(false);
        setCategory(res?.data?.data?.category);
        setCourses(res?.data?.data?.coursesWithModulesAndChapters);
      }
    } catch (error) {
      setLoader(false);

      if (error?.response?.status == 401) {
        logOutApi();
      }
    }
  };

  // Home Cta

  const homeCtaApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${lmsHomeCta}`);
      if (res?.data?.success) {
        setHomeCta(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }

    setLoader(false);
  };

  useEffect(() => {
    getHomeApi();
    homeCtaApi();
    setAsModule(false);
  }, [addCartEffect, removeCartEffect, bookMarkEffect]);

  return (
    <LmsLayout>
      {loader ? (
        <PageLoader />
      ) : (
        <Fragment>
          <LmsHomeSubjects category={category} />
          <LmsHomeClasses homeCta={homeCta} />
          <HomeRecommended courses={courses} />
          <HomeShareApp />
        </Fragment>
      )}
    </LmsLayout>
  );
};

export default LmsHome;
