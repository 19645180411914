import "./PrivacyPolicy.scss";
import WebLayout from "../../components/weblayout/WebLayout";

const PrivacyPolicy = () => {
  return (
    <WebLayout>
      <section className="privacy_policy">
        <div className="content_wrap p_b">
          <h2 className="text-center">Privacy Policy</h2>
          <p>
            This Privacy Policy document describes the types of information that
            are collected and recorded by
            “https://www.inauditusintervention.com” (“Website”), owned and
            operated by Inauditus Intervention Pvt. Ltd. (“Inauditus”), and how it
            is used. The Website makes several services (“Services”) accessible
            to the user (“User”) including but not limited to viewing
            information related to Inauditus, courses presented via various
            media through the Learning Management System, and the ability to
            contact Inauditus.
          </p>
          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us at{" "}
            <a href="mailto:contact@inauditusintervention.com" target="_blank">
              contact@inauditusintervention.com
            </a>
            .
          </p>
          <p>
            This document is published in accordance with the provisions of the
            Information Technology Act, 2000 (Act 2000/21 of the Indian
            Parliament) and EU General Data Protection Regulation (Regulation
            (EU) 2016/679 of the European Parliament and of the Council of 27
            April 2016) and the rules made thereunder that require publishing
            the rules and regulations, privacy policy on an online portal of the
            Company. We request you to go through this Privacy Policy carefully
            before you decide to access the Website and avail the Services.
          </p>

          <h3>General Data Protection Regulation (GDPR)</h3>
          <p>We are a Data Controller of your information.</p>
          <p>
            Inauditus’ legal basis for collecting and using the personal
            information described in this Privacy Policy depends on the Personal
            Information we collect and the specific context in which we collect
            the information.
          </p>
          <p>
            Inauditus will retain your personal information only for as long as
            is necessary for the purposes set out in this Privacy Policy. We
            will retain and use your information to the extent necessary to
            comply with our legal obligations, resolve disputes, and enforce our
            policies.
          </p>
          <p>
            If you are a resident of the European Economic Area (EEA), you have
            certain data protection rights. If you wish to be informed what
            Personal Information we hold about you and if you want it to be
            removed from our systems, please contact us.
          </p>
          <p>
            In certain circumstances, you have the following data protection
            rights:
          </p>
          <ul>
            <li>
              The right to access, update or to delete the information we have
              on you.
            </li>
            <li>The right of rectification.</li>
            <li>The right to object.</li>
            <li>The right of restriction.</li>
            <li>The right to data portability.</li>
            <li>The right to withdraw consent.</li>
          </ul>
          <h3>Collection of Information</h3>
          <ul>
            <li>
              Inauditus may collect and process information from you, through
              your access and use of the Website and Services. We may collect
              and process personal information provided by you, including but
              not limited to any information that identifies or can be used to
              identify, contact, or locate you, such as your name, address,
              email address, phone number, gender, photograph, date of birth,
              bank account details, login credentials and passwords.
            </li>
            <li>
              We may also receive data from or about the computer, mobile phone,
              or other devices you use to access the Website, including when
              multiple users log in from the same device. This may include
              network and communication information, such as your IP address or
              phone number, and other information about things like your
              internet service, operating system, location, the type (including
              identifiers) of the device or browser you use, or the pages you
              visit. For example, we could request device information to improve
              how our Services work on your device.
            </li>
            <li>
              We may also receive information regarding the details and
              transaction history in connection with your use of the Website.
            </li>
            <li>
              We may also get information about you from other sources. For
              example, if you create or log into your account through any
              associated service, we will receive information from that service
              (such as your username and profile information) via the
              authorization procedures used by that service. The information we
              receive depends on which services you authorize and any options
              that are available.
            </li>
            <li>
              We may use cookies, web beacons, tracking pixels, and other
              tracking technologies on the Website to help customize the Website
              and improve your experience.
            </li>
          </ul>
          <p>
            The information that we receive from you as detailed in the
            paragraph above is hereinafter, collectively referred to as
            “Personal Information.”
          </p>
          <h3>Use of the Information Collected</h3>
          <p>
            <b>
              The primary goal of the Company in collecting the Personal
              Information is to enable access to and use of the Website and
              Services by the User. We may use the Personal Information provided
              by you in the following ways:
            </b>
          </p>
          <ul>
            <li>To provide access to and use of the Website;</li>
            <li>To provide access to and use of the Services;</li>
            <li>
              To observe and analyze usage, diagnose problems and issues, and
              improve and administer the quality and performance of the Website
              and Service;
            </li>
            <li>
              To confirm your identity in order to determine your eligibility to
              access and use the Website and avail the Services;
            </li>
            <li>
              Remember the basic information provided by you for effective and
              efficient access to the Website and Service;
            </li>
            <li>
              To notify you about any changes to the Website, Services, and this
              Privacy Policy;
            </li>
            <li>
              For the purpose of sending administrative notices, Service related
              alerts, marketing communications and advertisements and similar
              communication from time to time;
            </li>
            <li>
              For internal operations such as market research, troubleshooting,
              project planning, and devising strategies against fraud and other
              criminal activity;
            </li>
            <li>
              To enforce the provisions of this Privacy Policy and Terms of Use;
              and
            </li>
            <li>
              To disclose your Personal Information to any of our affiliates or
              related entities in connection with the operation and management
              of the Website and Services in accordance with applicable laws.
            </li>
          </ul>

          <h3>Cookies and Web Beacons</h3>
          <p>
            Like any other website, the Website uses "cookies". Cookies
            (“Cookies”) are small portions of information saved by your browser
            onto your computer or mobile. They are used to record various
            aspects of your visit and assist us to provide you with
            uninterrupted service. These cookies are used to store information
            including visitors' preferences, and the pages on the website that
            the visitor accessed or visited. The information is used to optimize
            the users' experience by customizing our web page content based on
            visitors' browser type and/or other information.
          </p>
          <p>
            We may use information collected from our Cookies to identify user
            behavior and to serve content and offers based on your profile, in
            order to personalize your experience and in order to enhance the
            convenience of the users of our Website and Services.
          </p>
          <p>We collect the following types of cookies:</p>
          <ul>
            <li>Strictly Necessary cookies</li>
            <li>Performance cookies</li>
            <li> Functional cookies</li>
            <li> Targeting cookies</li>
          </ul>

          <p>
            You can choose to disable some cookies through the website’s cookie
            popup or your individual browser options. More detailed information
            about cookie management with specific web browsers can be found at
            the browsers' respective websites.
          </p>
          <p>
            You may not decline web beacons. However, they can be rendered
            ineffective by declining all cookies or by modifying your web
            browser’s settings to notify you each time a cookie is tendered,
            permitting you to accept or decline cookies on an individual basis.
          </p>
          <p>
            You acknowledge and agree that third party service providers may use
            Cookies or similar technologies to collect information about your
            pattern of accessing and using the Website and availing the
            Services, in order to inform, optimize, and provide advertisements
            based on your visits to the Website and general browsing pattern and
            report how the third party service provider’s advertisement
            impressions and interactions with these impressions and services are
            in relation to your visits on such third party’s website.
          </p>
          <p>
            We neither have access to, nor does the Privacy Policy govern the
            use of Cookies or other tracking technologies that may be placed by
            third party service providers on the Website. These parties may
            permit you to opt out of tailored advertisement at any time,
            however, to the extent advertising technology is integrated into the
            Website and Services, you may still receive advertisements and
            related updates even if they or you choose to opt-out of tailored
            advertising. We assume no responsibility or liability whatsoever for
            your receipt or use of such tailored advertisements.
          </p>
          <p>
            We may use any data that is automatically captured by the cookies on
            our Website and Services including your device name, operating
            system version, configuration of the application, time, and date of
            your use of the Website and Service, IP address and other such
            related statistics. Every computer/mobile device connected to the
            internet is given a domain name and a set of numbers that serve as
            that computer’s or device’s IP address. When you request a page from
            any page within the Website, our web servers automatically recognize
            your domain name and IP address to help us identify your location.
            The domain name and IP address reveal nothing personal about you
            other than the IP address from which you have accessed the Website.
          </p>

          <h3>Log Files</h3>
          <p>
            The Website’s host server follows a standard procedure of using log
            files. These files log visitors when they visit websites. All
            hosting companies do this as part of hosting services' analytics.
            The information collected by log files include internet protocol
            (IP) addresses, browser type, Internet Service Provider (ISP), date
            and time stamp, referring/exit pages, and possibly the number of
            clicks. These are not linked to any information that is personally
            identifiable. The purpose of the information is for analyzing
            trends, administering the site, tracking users' movement on the
            website, and gathering demographic information.
          </p>
          <h2>Sharing of Information</h2>
          <h3>Sharing</h3>
          <p>
            Other than as mentioned elsewhere in this Privacy Policy, we may
            share aggregated demographic information with third parties. This is
            not linked to any Personal Information that can identify an
            individual person. We will however not be liable for transfer of any
            Personal Information resulting from failure of third-party service
            providers, cyber-attacks or terrorism or any other event beyond the
            Company’s control.
          </p>
          <h3>Consulting</h3>
          <p>
            We may partner with another third party to provide certain specific
            services if required. When you sign-up for such services, we may
            share such Personal Information that is necessary for the third
            party to provide these services. Our contractual arrangements with
            such third parties restrict these parties from using Personal
            Information except for the explicit purpose of providing the
            specific services agreed to be provided by such a third party.
          </p>
          <h3>Targeted Advertising</h3>
          <p>
            You expressly acknowledge and agree that we may also share or
            transfer information (including Personal Information) in relation to
            your browsing history, cache, IP address and domain name to
            third-party service providers, for the limited purpose of allowing
            or permitting such third-party service providers, including social
            media websites to map similar audiences and display advertisements
            and notifications on websites across the Internet, based on the
            information that is collected by us. Personal Information may also
            be shared or transferred to optimize and display advertisements
            based on your past preferences and visits to the Website. We do not
            allow any unauthorized persons or organization to use any
            information that we may collect from you through this Website.
          </p>
          <h3>Regulatory Disclosures</h3>
          <p>
            We cooperate with government and law enforcement officials and
            private parties to enforce and comply with the law. Thus, we may
            access, use, store, transfer and disclose your information
            (including Personal Information), including disclosure to third
            parties such as government or law enforcement officials or private
            parties as we reasonably determine is necessary and appropriate: (i)
            to satisfy any applicable law, regulation, governmental requests or
            legal process; (ii) to protect the safety, rights, property or
            security of the Company, our Services, the Website or any third
            party; (iii) to protect the safety of the public for any reason;
            (iv) to detect, prevent or otherwise address fraud, security or
            technical issues; and /or (v) to prevent or stop any activity we
            consider to be, or to pose a risk of being, an illegal, unethical,
            or legally actionable activity. Such disclosures may be carried out
            without notice to you.
          </p>

          <h3>Referrals</h3>
          <p>
            When you email/message a Service available on the Website to a
            friend, you and your friend’s names and email addresses/numbers are
            requested. This ensures that your friend will know that you have
            requested that we send them an e-mail/message. Your email and your
            friends’ email addresses will only be used for this purpose unless
            permission is otherwise granted.
          </p>

          <h3>Link to Third Party Websites</h3>
          <p>
            The Website and Services may contain links which may lead you to
            other websites, applications and services offered by third parties.
            Please note that once you leave the Company’s Website and Services,
            you will be subjected to the privacy policy of such other third
            parties. The linked websites, applications and services are not
            necessarily under our control. Please be aware that we are not
            responsible or liable for the privacy practices of such third
            parties. We encourage you to read the privacy policies of each and
            every such third party. If you decide to access any of the
            third-party websites, applications, and services, you do this
            entirely at your own risk.
          </p>

          <h3>Security of Information</h3>

          <p>
            We have put in place necessary security practices and procedures to
            safeguard and secure your Personal Information against unauthorized
            disclosure and use. We only process Personal Information in
            accordance with this Privacy Policy. Access to your Personal
            Information is limited to employees, agents, or partners and third
            parties, who we reasonably believe will need that information to
            enable the Company to provide Services to you. However, we are not
            responsible for the confidentiality, security, or distribution of
            your Personal Information by our agents, partners and third parties.
          </p>
          <p>
            In using the Website and Services, you accept the inherent security
            implications of data transmission over the internet. Therefore, the
            use of the Website and Services will be at your own risk, and we
            assume no liability for any disclosure of information due to errors
            in transmission, unauthorized third-party access or other acts of
            third parties, or acts or omissions beyond our reasonable control
            and you agree not to hold the Company responsible for any breach of
            security.
          </p>
          <p>
            In the event we become aware of any breach of the security of your
            information, we will promptly notify you and take appropriate action
            to the best of its ability to remedy such a breach.
          </p>
          <h3>Exclusion</h3>
          <p>
            This Privacy Policy does not apply to any information other than
            information collected by the Company through the Website and
            Services including such information collected in accordance with the
            section above on “Collection of Information”. This Privacy Policy
            will not apply to any information available in the public domain and
            unsolicited information provided by you through the Website and
            Services or through any other means. This includes, but is not
            limited to, information posted on any public areas of the Website.
            All such unsolicited information shall be deemed to be
            non-confidential, and we will be free to use, disclose such
            unsolicited information without limitation.
          </p>
          <h3>Right to Withdraw Consent</h3>
          <p>
            The consent that you provide for the collection, use and disclosure
            of your Personal Information will remain valid until such time it is
            withdrawn by you in writing by sending an email to
            contact@inauditusintervention.com If you withdraw your consent, we
            will stop processing the relevant Personal Information except to the
            extent that we have other grounds for processing such Personal
            Information under applicable laws. We will respond to your request
            within a reasonable timeframe.
          </p>

          <h3>Data Retention</h3>
          <p>
            We shall not retain Personal Information longer than is necessary to
            fulfill the purposes for which it was collected and as permitted by
            applicable law. Subject to applicable law, if you delete your
            account or withdraw your consent for the collection, use, and
            disclosure of your Personal Information, we may retain your Personal
            Information as needed to comply with our legal and regulatory
            obligations, resolve disputes, conclude any activities related to
            the deletion of an account, to investigate or prevent fraud and
            other inappropriate activity, to enforce our agreements, and for
            other business reasons.
          </p>
          <h3>Right to Correction</h3>
          <p>Right to Correction</p>
          <h3>Opt-Out Procedure</h3>
          <p>
            You may opt-out of receiving marketing and certain other kinds of
            non-account related communications from the Company by clicking on
            the “unsubscribe” button or by sending an email to
            contact@inauditusintervention.com
          </p>
          <h3>Notification of Changes</h3>
          <p>
            From time to time, we may update this Privacy Policy to reflect
            changes to our information practices with due notice to you via the
            Website or by email. You acknowledge that it is your responsibility
            to check this Privacy Policy for updates periodically. Your
            continued use of the Website and Services following the posting of
            any changes to this Privacy Policy will be deemed to constitute an
            acceptance of those changes.
          </p>

          <h3>Grievance Redressal</h3>
          <p>
            In the event that you wish to raise a query or complaint with us,
            please contact us at{" "}
            <a href="mailto:contact@inauditusintervention.com" target="_blank">
              contact@inauditusintervention.com
            </a>{" "}
            and your complaint shall be acknowledged within 48 (forty-eight)
            hours from the time of receipt of such complaint. Kindly note that
            once your complaint is received, we shall use our best efforts to
            redress it within a period of 15 (fifteen) working days from the
            date of receipt of such complaint.
          </p>

          <h3>Consent</h3>
          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </p>
        </div>
      </section>
    </WebLayout>
  );
};
export default PrivacyPolicy;
