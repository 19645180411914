import "./CreateNewPassword.scss";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextError from "../../../components/textError/TextError";
import { baseUrl, setNewPassword } from "../../../utils/apiData";
import axios from "axios";
import { useContext, useState } from "react";
import { webContext } from "../../../webContext/WebContext";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const CreateNewPassword = (props) => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const { toastError, toastSuccess } = useContext(webContext);

  const verifyOtpAPI = async (values) => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${setNewPassword}`, {
        ...values,
      });
      if (res?.data?.success) {
        toastSuccess(res?.data?.message);
        setLoader(false);
        props?.closer();
        navigate("/signin");
       
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const initialValues = {
    email: props.email,
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("invalid email formate!")
      .required("email is required!"),
    newPassword: Yup.string().required("Field is required!"),
    confirmPassword: Yup.string().required("Field is required!"),
  });
  const onSubmit = (values, { resetForm }) => {
    verifyOtpAPI(values);
    resetForm();
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="verify_otp_modal"
    >
      <Modal.Body>
        <button className="close" onClick={props.closer}>
          <IoMdClose />
        </button>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="title">
              <h3>Verify OTP</h3>
              <p>Enter your registered email address</p>
            </div>

            <div className="field_wrap">
              <label>Email</label>
              <Field
                type="text"
                placeholder="e.g. email@gmail.com"
                id="email"
                name="email"
                autoComplete="off"
                readOnly
              />
              <ErrorMessage name="email" component={TextError} />
            </div>

            <div className="field_wrap">
              <label>New Password</label>
              <Field
                type="text"
                placeholder="Enter newpassword"
                id="newPassword"
                name="newPassword"
                autoComplete="off"
              />
              <ErrorMessage name="newPassword" component={TextError} />
            </div>

            <div className="field_wrap">
              <label>Confirm Password</label>
              <Field
                type="text"
                placeholder="Confirm newpassword"
                id="confirmPassword"
                name="confirmPassword"
                autoComplete="off"
              />
              <ErrorMessage name="confirmPassword" component={TextError} />
            </div>

            <button
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{
                duration: 0.6,
                ease: "easeInOut",
                delay: 0.4,
              }}
              type="submit"
              className="normal_btn bordered otpsubmit"
            >
              {loader ? "Loading..." : "Set Password"}
            </button>
          </Form>
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateNewPassword;
