import "./AboutLanding.scss";
import { Fragment } from "react";
import { images } from "../../../utils/images";
import { motion } from "framer-motion";
import { FaArrowsAltV } from "react-icons/fa";

const AboutLanding = () => {

  
const altA="Isometric illustration of six individuals interacting with technology on a multi-level platform.";
const altB="Isometric illustration of four people working on laptops, seated on colorful bean bag chairs, with a large light bulb overhead";
const altC="Isometric illustration of a person climbing stairs to a large computer monitor displaying two people working on laptops.";



  return (
    <Fragment>
      {/* for width > 992 */}

      <section className="about_landing p_t p_b">
        <div className="content_wrap">
          <img src={images.zigzag} alt="bg" className="bg" />
          <div className="grid_a">
            <img
              src={images.aboutLandingArrowA}
              alt="arrow"
              className="arrow_a"
            />
            <div className="first">
              <motion.h1
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                className="big"
              >
                Committed to Fostering
              </motion.h1>
            </div>

            <div className="second">
              <motion.img
                initial={{ opacity: 0, rotate: -45, scale: 0 }}
                whileInView={{ opacity: 1, rotate: 0, scale: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                src={images.aboutA}
                alt={altA}
              />
            </div>

            <div className="third">
              <motion.h1
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                className="big"
              >
                Growth & Success
              </motion.h1>
            </div>
          </div>

          <div className="grid_b">
            <img
              src={images.aboutLandingArrowB}
              alt="arrow"
              className="arrow_b"
            />
            <div className="a">
              <motion.h1
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                className="big"
              >
                At Every Level
              </motion.h1>
            </div>
            <div className="b">
              <motion.img
                initial={{ opacity: 0, rotate: -45, scale: 0 }}
                whileInView={{ opacity: 1, rotate: 0, scale: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                src={images.aboutB}
                alt={altB}
              />
            </div>
            <div className="c">
              <motion.p
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
              >
                We’re dedicated to empowering individuals, teams, and
                organizations to thrive in evolving and competitive business
                landscapes.
              </motion.p>
              <img src={images.aboutLandingArrowC} className="arrow_c" alt="arrow" />
            </div>
            <div className="d">
              <motion.img
                initial={{ opacity: 0, rotate: -45, scale: 0 }}
                whileInView={{ opacity: 1, rotate: 0, scale: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                src={images.aboutC}
                alt={altC}
              />
            </div>
          </div>
        </div>
      </section>

      {/* for width <= 992 */}

      <section className="about_landing_tab p_t p_b">
        <div className="content_wrap">
          <img src={images.zigzag} alt="bg" className="bg" />
          <div className="title">
            <motion.h1
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="big"
            >
              Committed to Fostering Growth & Success
            </motion.h1>
            <motion.h1
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
              className="big"
            >
              at Every Level
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.4 }}
            >
              We’re dedicated to empowering individuals, teams, and
              organizations to thrive in evolving and competitive business
              landscapes.
            </motion.p>
            <div className="img_wrap">
              <motion.img
                initial={{ opacity: 0, rotate: -45, scale: 0 }}
                whileInView={{ opacity: 1, rotate: 0, scale: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                src={images.aboutA}
                alt={altA}
              />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AboutLanding;
