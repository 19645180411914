import { useNavigate } from "react-router-dom";
import TextPill from "../../../../components/textPill/TextPill";
import "./CourseListCard.scss";

const CourseListCard = ({ data }) => {
  const pillTheme = {
    upcoming: "#206583",
    courses: "#49BDB6",
  };

  const navigate = useNavigate();

  return (
    <div className="course_list_card" onClick={() => navigate(`/offerings/courses/${data.slug}`)}>
      <div className="pill_box">
        <TextPill title={data.category === "courses" ? "Course" : data.category === "upcoming" ? "Upcoming" : ""} theme={pillTheme[data.category]} />        
      </div>
      <div className="img_card">
        <img src={data.image} alt="blog" />
      </div>
      <p className="text">{data.subtitle}</p>
      <h4>{data.name}</h4>
    </div>
  );
};

export default CourseListCard;
