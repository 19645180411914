import "./CourseAcc.scss";
import Accordion from "react-bootstrap/Accordion";
import TextPill from "../../../components/textPill/TextPill";
import { TfiAngleDown } from "react-icons/tfi";
import { GoCheckCircleFill } from "react-icons/go";
import { motion } from "framer-motion";

const CourseAcc = ({data}) => {
  return (
    <section className="course_acc p_b">
      <div className="content_wrap">
        <div className="bi_sec">
          <div className="text">
            <motion.h2
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
         FAQ
            </motion.h2>
          </div>
          <div className="accordion_side">
            <Accordion>

              {
                data?.map((data, j) => {
                  return(
                    <Accordion.Item eventKey={j} key={data?._id}>
                    <Accordion.Header>
                      <div className="btn_content">
                        <div className="arrow_line">
                          <TextPill title="FAQ" theme={["#49BDB6", "#206583", "#eeabab", "#ED6869"][`${j % 3}`]} />
                          <TfiAngleDown />
                        </div>
                        <h4>
                       {data?.name || "-"}
                        </h4>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                      {data?.answer || "-"}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  )
                })
              }
            


            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseAcc;
