import LmsLayout from "../lmsComponents/lmsLayout/LmsLayout";
import "./LmsLessons.scss";
import LessonsInfoList from "./lessonsinfolist/LessonsInfoList";

const LmsLessons = () => {
  return (
    <LmsLayout>
      <LessonsInfoList />
    </LmsLayout>
  );
};

export default LmsLessons;
