import "./Needs.scss";
import { motion } from "framer-motion";

const Needs = ({ data }) => {
  const type = ["a", "b", "c", "d"];
  return (
    <section className="needs p_t p_b">
      <div className="content_wrap">
        <div className="title">
          <motion.h2
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            Built for Your Needs
          </motion.h2>
        </div>
        <div className="four_grid">

          {data?.map((item, i) => {
            return (
              <motion.div
                initial={{ opacity: 0, translateY: 60 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                className={`card_info ${type[i % 4]}`}
                key={item?._id}
              >
                <div className="icon_line">
                  <div className="icon_box">
                    <img src={item.image} alt="inauditus" />
                  </div>
                </div>
                <div className="text">
                  <h4>{item?.title || "-"}</h4>
                  <p>{item?.description || "-"}</p>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Needs;
