import "./BlogsList.scss";
import { images } from "../../../utils/images";
import { Fragment } from "react";
import BlogCard from "./blogCard/BlogCard";
import { motion } from "framer-motion";

const BlogsList = ({ blogs }) => {

  return (
    <section className="blog_list">
      <div className="content_wrap">
        <motion.h3
          initial={{ opacity: 0, translateX: 100 }}
          whileInView={{ opacity: 1, translateX: 0 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          All Blogs
        </motion.h3>
        {
          blogs?.length > 0 ?
        
        <div className="blog_grid p_b">
          {blogs.map((data) => {
            return (
              <Fragment key={data._id}>
                <BlogCard data={data} />
              </Fragment>
            );
          })}
        </div> : <p className="text-center p_t p_b">Data not found...</p>
        }
      </div>
    </section>
  );
};
export default BlogsList;
