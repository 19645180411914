import { Fragment, useContext, useState } from "react";
import "./AssignmentSidebar.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import { motion } from "framer-motion";
import { images } from "../../../utils/images";
import axios from "axios";
import { baseUrl, submitAssignment } from "../../../utils/apiData";
import { FaCheckDouble } from "react-icons/fa6";
import { webContext } from "../../../webContext/WebContext";
import { useNavigate, useParams } from "react-router-dom";

const AssignmentSidebar = ({ assignStatus, assignClose }) => {
  const { toastSuccess, toastError, logOutApi, setAssSubEffect } =
    useContext(webContext);
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const assignmentSubmitApi = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${submitAssignment}`,
        { assignment: file, chapterId:id },
        { headers }
      );

      if (res?.data?.success) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2500);

        setFile(null);
        setLoader(false);
        toastSuccess(res?.data?.message || "Submit Success!");
        navigate(`/auth/chapter/${id}`);
        setAssSubEffect((prev) => prev + 1);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
      if (error?.response?.status == 401) {
        logOutApi();
      }
    }
  };

  return (
    <Offcanvas
      show={assignStatus}
      onHide={assignClose}
      placement="end"
      className="assignment"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h4>Assignment</h4>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {success ? (
          <div className="success_assignment">
            <motion.img
              initial={{ scale: 0.5, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{
                duration: 0.6,
                ease: "easeInOut",
              }}
              src={images.successSketch}
              alt="success"
            />
            <motion.h5
              initial={{ scale: 0.5, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{
                duration: 0.6,
                ease: "easeInOut",
                delay: 0.2,
              }}
            >
              Your assignment has been submitted successfully
            </motion.h5>
          </div>
        ) : (
          <Fragment>
            <p>
              Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>
            <div className="file_upload_wrap">
              <input type="file" id="file" onChange={handleFileChange} />

              {file ? (
                <Fragment>
                  <FaCheckDouble />
                  <p>Name : {file?.name || "-"}</p>
                  <p>Size : {file?.size || "-"}</p>
                  <p>Type : {file?.type || "-"}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <MdOutlineDriveFolderUpload />
                  <p>Click to select file</p>
                  <p>.PDF, .Docx Under 10 MB</p>
                </Fragment>
              )}
            </div>
            <button
              type="button"
              className="secondary_btn"
              onClick={assignmentSubmitApi}
            >
              {loader ? "Loading..." : "Submit"}
            </button>
          </Fragment>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AssignmentSidebar;
