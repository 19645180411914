import { images } from "../../utils/images";
import "./SuccessModel.scss";
import Modal from 'react-bootstrap/Modal';

const SuccessModel = (props) => {
    return(
        <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >   
      <Modal.Body>
        <img src={images.successSketch} alt="success" />
        <h3>{props.title}</h3>
      </Modal.Body>      
    </Modal>
    )
}

export default SuccessModel;