import { createStore, combineReducers } from "redux";

import { courseBookmarkReducer } from "./apiReducers";

const rootReducer = combineReducers({
  courseBookmarkReducer,
});

const store = createStore(rootReducer);
export default store;
