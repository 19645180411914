import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Header.scss";
import NormalButton from "../normalButton/NormalButton";
import { RiMenu2Line } from "react-icons/ri";
import { images } from "../../utils/images";

const Header = ({ handleShow }) => {
  const navigate = useNavigate();

  return (
    <header>
      <div className="content_wrap">
        <NavLink to="/" className="logolink">
          <img src={images.logo} alt="Brand" />
        </NavLink>

        <nav>
          <div className="offering_dd">
            <NavLink to="/offerings">Offerings</NavLink>
            <div className="sub_navs">
              <NavLink to="/offerings/intervention">Intervention</NavLink>
              <NavLink to="/offerings/coaching">Coaching</NavLink>
              <NavLink to="/offerings/consultancy">Consultancy</NavLink>
              <NavLink to="/offerings/courses">Courses</NavLink>
            </div>
          </div>

          <NavLink to="/blogs">Resources</NavLink>
          <NavLink to="/about">About</NavLink>
        </nav>

        <div className="btn_wraper">
          <NormalButton
            title="Log In"
            type=""
            clickHandler={() => navigate("/signin")}
          />
          <NormalButton
            title="Contact"
            type="bordered"
            clickHandler={() => navigate("/contact")}
          />
          <button type="button" className="menu" onClick={handleShow}>
            <RiMenu2Line />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
