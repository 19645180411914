import { images } from "../../../utils/images";
import "./CourseGrid.scss";
import { motion } from "framer-motion";

const CourseGrid = ({ data }) => {
  return (
    <section className="course_grid">
      <div className="content_wrap p_t p_b">
        <div className="career_grid">
          <div className="white_card">
            <motion.h2
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
           Benefits
            </motion.h2>

           
          </div>

          <div className="info_card">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="num_box a"
            >
              <p>
                <b>01</b>
              </p>
            </motion.div>

            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              {data[0]?.title || "-"}
            </motion.h4>
            <motion.p
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            >
              {data[0]?.description || "-"}
            </motion.p>
          </div>

          <div className="info_card">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="num_box b"
            >
              <p>
                <b>02</b>
              </p>
            </motion.div>

            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              {data[1]?.title || "-"}
            </motion.h4>
            <motion.p
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            >
              {data[1]?.description || "-"}
            </motion.p>
          </div>

          <div className="text_card">
            <img src={images.careerArrow} alt="text" className="arrow" />
          </div>
          <div className="info_card">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="num_box c"
            >
              <p>
                <b>03</b>
              </p>
            </motion.div>

            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              {data[2]?.title || "-"}
            </motion.h4>
            <motion.p
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            >
              {data[2]?.description || "-"}
            </motion.p>
          </div>

          <div className="info_card">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="num_box d"
            >
              <p>
                <b>04</b>
              </p>
            </motion.div>

            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              {data[3]?.title || "-"}
            </motion.h4>
            <motion.p
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            >
              {data[3]?.description || "-"}
            </motion.p>
          </div>
          <div className="emptya"></div>
          <div className="emptyb"></div>
          <div className="info_card">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="num_box a"
            >
              <p>
                <b>05</b>
              </p>
            </motion.div>

            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              {data[4]?.title || "-"}
            </motion.h4>
            <motion.p
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            >
              {data[4]?.description || "-"}
            </motion.p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseGrid;
