import "./CoursesLanding.scss";
import { images } from "../../../utils/images";
import { motion } from "framer-motion";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { useNavigate } from "react-router-dom";

const CoursesLanding = () => {

  const navigate = useNavigate();

  return (
    <section className="courses_landing p_t p_b">
      <div className="content_wrap">
        <div className="bi_grid">
          <div className="left_content">
            <motion.h1
              initial={{ opacity: 0, translateY: 48 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="big"
            >
              Courses
            </motion.h1>
            <motion.div
              initial={{ scaleX: 0.2 }}
              whileInView={{ scaleX: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 0.2 }}
              className="h_line"
            ></motion.div>
          
            <motion.p
              initial={{ opacity: 0, translateY: 48 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            >
              Inauditus’ structured & engaging courses facilitate learning,
              development, and upskilling for individuals, institutions, and
              organizations.
            </motion.p>
          </div>
          <div className="img_wrap">
            <motion.img
              initial={{ opacity: 0, scale: 0.4 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              src={images.courses}
              alt="Workspace setup with an open laptop, notebook with a pen, a white mug, a small bottle, and flowers in a jar on a wooden table by a window."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoursesLanding;
