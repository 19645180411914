import { Fragment, useState, useEffect } from "react";
import LmsHeader from "../lmsHeader/LmsHeader";
import "./LmsLayout.scss";
import CartSideBar from "../cartSidebar/CartSideBar";
import LmsSideBar from "./lmsSideBar/LmsSideBar";

const LmsLayout = (props) => {
  const [cartStatus, setCartStatus] = useState(false);
  const [show, setShow] = useState(false);

  const openCart = () => {
    setCartStatus(true);
  };
  const closeCart = () => {
    setCartStatus(false);
  };


  useEffect(() => {
    const handleKeyPress = (event) => {
      if ([123].includes(event.keyCode)) {
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  

  return (
    <Fragment>
      <LmsHeader openCart={openCart} setShow={setShow}/>
      {props.children}
      <CartSideBar cartStatus={cartStatus} closeCart={closeCart} />
      <LmsSideBar show={show} handleClose={() => setShow(false)} />
    </Fragment>
  );
};

export default LmsLayout;
