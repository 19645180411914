import "./App.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import ErrorPage from "./pages/errorPage/ErrorPage";
import { Fragment, useEffect } from "react";
import { routeingData } from "./utils/routingData";

// LMS Pages New Test
import WebContextProvider from "./webContext/WebContext";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <WebContextProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Routes>
        {routeingData.map((data) => {
          return (
            <Fragment key={data.id}>
              <Route path={`${data?.to}`} element={data?.ele} />
            </Fragment>
          );
        })} 

        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </WebContextProvider>
  );
}

export default App;
