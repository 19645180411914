import WebLayout from "../../components/weblayout/WebLayout";
import BlogLanding from "./blogLanding/BlogLanding";
import BlogsList from "./blogsList/BlogsList";
import CtaInfo from "./ctaInfo/CtaInfo";
import LatestBlogs from "./latestBlogs/LatestBlogs";
import PageLoader from "../../lms/lmsComponents/pageLoader/PageLoader";

import axios from "axios";
import { baseUrl, getBlog } from "../../utils/apiData";
import { Fragment, useEffect, useState } from "react";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loader, setLoader] = useState([]);

  const getBlogApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getBlog}`);
      if (res?.data?.success) {
        setLoader(false);
        setBlogs(res?.data?.data);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getBlogApi();
  }, []);

  return (
    <WebLayout>
      {loader ? (
        <PageLoader />
      ) : (
        <Fragment>
          <BlogLanding/>
          <LatestBlogs blogs={blogs} latestBlog = {blogs[0]}  />
          <CtaInfo />
          <BlogsList blogs={blogs} />
        </Fragment>
      )}
    </WebLayout>
  );
};
export default Blogs;
