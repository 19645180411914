import "./DataCounter.scss";
import Counter from "../../../components/counter/Counter";
import { motion } from "framer-motion";

const DataCounter = ({ home }) => {
  return (
    <section className="data_counter p_b">
      <div className="content_wrap">
        <div className="title_wrap p_b">
          <motion.h2
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            {home?.section_1_title || "-"}
          </motion.h2>
        </div>

        <div className="counter_grid">
          <motion.div
            initial={{ opacity: 0, translateY: 100 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className="counter_card a"
          >
            <h1>
              <span>
                <Counter
                  count={Number.parseFloat(home?.section_2_percentage || "0")}
                />
              </span>
              %
            </h1>
            <div className="count_progress">
              <motion.div
                initial={{ scaleX: 0.3 }}
                whileInView={{ scaleX: 1 }}
                transition={{ duration: 2, ease: "easeInOut" }}
                className="count_filler"
                style={{ width: "85.2%" }}
              ></motion.div>
            </div>
            <p>{home?.section_2_title || "-"}</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            className="counter_card b"
          >
            <h1>
              <span>
                <Counter
                  count={Number.parseFloat(home?.section_3_percentage || "0")}
                />
              </span>
              %
            </h1>
            <div className="count_progress">
              <motion.div
                initial={{ scaleX: 0.3 }}
                whileInView={{ scaleX: 1 }}
                transition={{ duration: 2, ease: "easeInOut" }}
                className="count_filler"
                style={{ width: "70%" }}
              ></motion.div>
            </div>
            <p>{home?.section_3_title || "-"}</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.4 }}
            className="counter_card c"
          >
            <h1>
              <span>
              <Counter
                  count={Number.parseFloat(home?.section_4_percentage || "0")}
                />
              </span>
              %
            </h1>
            <div className="count_progress">
              <motion.div
                initial={{ scaleX: 0.3 }}
                whileInView={{ scaleX: 1 }}
                transition={{ duration: 2, ease: "easeInOut" }}
                className="count_filler"
                style={{ width: "87.2%" }}
              ></motion.div>
            </div>
            <p>{home?.section_4_title || "-"}</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default DataCounter;
