import WebLayout from "../../components/weblayout/WebLayout"
import CoursesLanding from "./coursesLanding/CoursesLanding";
import CoursesList from "./coursesList/CoursesList";

const Courses = () => {
    return(
        <WebLayout>
            <CoursesLanding />
            <CoursesList />
        </WebLayout>
    )
}

export default Courses;