import "./NormalButton.scss";
import { motion } from "framer-motion";

const NormalButton = ({ title, clickHandler, type, delay, icon }) => {

  return (
    <motion.button
    initial={{ opacity: 0, translateY: 60 }}
    whileInView={{ opacity: 1, translateY: 0 }}
    transition={{ duration: 0.6, ease: "easeInOut", delay:delay }}

      type="button"
      className={`normal_btn ${type}`}
      onClick={clickHandler}
      
    >
      {icon}
      {title}
    </motion.button>
  );
};

export default NormalButton;
