import { Fragment, useContext, useState, useEffect } from "react";
import LmsLayout from "../lmsComponents/lmsLayout/LmsLayout";
import "./LmsFaq.scss";
import Accordion from "react-bootstrap/Accordion";
import { LiaAngleUpSolid } from "react-icons/lia";
import axios from "axios";
import { baseUrl, getFaqs } from "../../utils/apiData";
import { webContext } from "../../webContext/WebContext";
import PageLoader from "../lmsComponents/pageLoader/PageLoader";
import { IoSearchOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import { images } from "../../utils/images";

const LmsFaq = () => {
  const { toastError } = useContext(webContext);
  const [loader, setLoader] = useState(false);

  const [keyword, setKeyword] = useState("");

  const [faq, setFaq] = useState([]);

  const SearchHandler = (e) => {
    setKeyword(e.target.value);
  };

  const getFaqApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getFaqs}?query=${keyword}`);
      if (res?.data?.success) {
        setFaq(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      getFaqApi();
    },600);

    return () => clearTimeout(delayInputTimeoutId);
  }, [keyword]);

  return (
    <LmsLayout>
      <section className="lms_faq">
        <div className="content_wrap">
          <motion.div
            initial={{ translateY: 48, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            className="faq_search_wrap"
          >
            <span>
              <IoSearchOutline />
            </span>
            <input
              type="text"
              placeholder="Search"
              value={keyword}
              onChange={SearchHandler}
            />
            
          </motion.div>

          {loader ? (
            <PageLoader />
          ) : faq?.length > 0 ? (
            <Accordion>
              {faq.map((item, j) => {
                return (
                  <Fragment key={item._id}>
                    <Accordion.Item eventKey={j}>
                      <Accordion.Header>
                        <p>{item.name}</p> <LiaAngleUpSolid />
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>{item.answer}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Fragment>
                );
              })}
            </Accordion>
          ) : (
            <p className="my-5">Data not found...</p>
          )}
        </div>
      </section>
    </LmsLayout>
  );
};

export default LmsFaq;
