import { NavLink } from "react-router-dom";
import "./LmsHeader.scss";
import { images } from "../../../utils/images";
import { GrCart } from "react-icons/gr";

import NotificationDd from "./notificationDd/NotificationDd";
import ProfileDd from "./profileDd/ProfileDd";
import { RiMenu5Line } from "react-icons/ri";
import { useEffect, useState } from "react";

const LmsHeader = ({ openCart, setShow }) => {
  const [isCart, setIsCart] = useState(false);

  useEffect(() => {
    const cart = localStorage.getItem("cart");
    setIsCart(cart);
  }, []);

  return (
    <header className="lms">
      <div className="content_wrap">
        <NavLink to="/auth/home" className="logolink">
          <img src={images.logo} alt="Brand" />
        </NavLink>

        <nav>
          <NavLink to="/auth/home">Home</NavLink>
          <NavLink to="/auth/courses">Courses</NavLink>
          <NavLink to="/auth/bookmark">Bookmarks</NavLink>
        </nav>

        <div className="btn_wraper">
          {/* {isCart && (
            <button type="button" className="cart active" onClick={openCart}>
              <GrCart />
            </button>
          )} */}

          <NotificationDd />
          <ProfileDd />
          <button
            type="button"
            className="auth_menu"
            onClick={() => setShow(true)}
          >
            <RiMenu5Line />
          </button>
        </div>
      </div>
    </header>
  );
};

export default LmsHeader;
