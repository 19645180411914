import "./Quote.scss";
import { images } from "../../../utils/images";
import { motion } from "framer-motion";

const Quote = ({data}) => {
  return (
    <section className="quote p_b">
      <div className="content_wrap">
        <motion.div
          initial={{ opacity: 0, translateY: 60, scale: 0.6 }}
          whileInView={{ opacity: 1, translateY: 0, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
          className="quote_card"
        >
          <motion.img
            initial={{ opacity: 0, scale: 0, translateY: 40 }}
            whileInView={{ opacity: 1, scale: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            src={images.quoteIcon}
            alt="course"
          />
          <motion.h3
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
          >
          {data?.description || "-"}
          </motion.h3>
          <motion.p
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.3 }}
            className="t_18"
          >
           {data?.name || "-"}
          </motion.p>
          <motion.p
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.4 }}
          >
            {data?.position || "-"}
          </motion.p>
          <motion.p
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.5 }}
          >
            {data?.location || "-"}
          </motion.p>
        </motion.div>
      </div>
    </section>
  );
};
export default Quote;
