import "./OurVision.scss";
import { images } from "../../../utils/images";
import { motion } from "framer-motion";

const OurVision = ({about}) => {
  return (
    <section className="our_vision p_b">
      <div className="content_wrap">
        <img src={images.zigzag} alt="bg" className="bg" />
        <div className="title">
          <motion.h2
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            Our Approach
          </motion.h2>
        </div>
        <div className="vision_grid">
          <div className="vision_card aa">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="num_box a"
            >
              <p>01</p>
            </motion.div>
            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
             {about?.approch_1_title || "-"}
            </motion.h4>
          </div>

          <div className="vision_card bb">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.1 }}
              className="num_box b"
            >
              <p>02</p>
            </motion.div>
            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
                {about?.approch_2_title || "-"}
            </motion.h4>
          </div>

          <div className="vision_card cc">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
              className="num_box c"
            >
              <p>03</p>
            </motion.div>
            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
                  {about?.approch_3_title || "-"}
            </motion.h4>
          </div>

          <div className="vision_card dd">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.3 }}
              className="num_box d"
            >
              <p>04</p>
            </motion.div>
            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
                {about?.approch_4_title || "-"}
            </motion.h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurVision;
