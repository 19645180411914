import "./TextError.scss";


const TextError = (props) => {
  return (
    <div className="error_field">      
      <p>{props.text ? props.text : props.children}</p>
    </div>
  );
};

export default TextError;