import { images } from "../../utils/images";
import LmsLayout from "../lmsComponents/lmsLayout/LmsLayout";
import "./PaymentSuccess.scss";
import { useNavigate } from "react-router-dom";
import { baseUrl, paymentSuccessHome } from "../../utils/apiData";
import axios from "axios";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const paymentSuccessApi = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(`${baseUrl}/${paymentSuccessHome}`, {headers});
      if(res?.data?.success){

      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LmsLayout>
      <section className="payment_sucess">
        <div className="content_wrap">
          <img src={images.successSketch} alt="success" />
          <h1>Payment Success!</h1>
          <p>Congratulations, Payment get successfully!</p>
          <button
            type="button"
            className="normal_btn bordered"
            onClick={() => navigate("/auth/courses")}
          >
            Courses
          </button>
        </div>
      </section>
    </LmsLayout>
  );
};

export default PaymentSuccess;
