import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "./LinkedInVerificationPage.scss";
import PageLoader from "../../lms/lmsComponents/pageLoader/PageLoader";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { baseUrl, userLogin } from "../../utils/apiData";
import { webContext } from "../../webContext/WebContext";

const LinkedInVerificationPage = () => {
  const [loader, setLoader] = useState(false);
  const { toastClear, toastWarning, toastError, toastInfo, toastSuccess } =
    useContext(webContext);

  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");

  const navigate = useNavigate();

  // Login API

  const signInApi = async (values) => {

    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${userLogin}`, { ...values });
      if (res?.data?.success) {
        setLoader(false);
        localStorage.setItem("token", res?.data?.data?.token);
        localStorage.setItem("cart", res?.data?.data?.user?.is_cart);
        localStorage.setItem("name", res?.data?.data?.user.name);

        toastSuccess(res?.data?.message || "Login Successful!");
        navigate("/auth/home");
      } else {
        toastError(res?.data?.message || "Login Failed!");
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  // Get Token API

  const accessLinkedinInfoAPi = async () => {
    try {
      const res = await axios.post(
        `${baseUrl}/api/linkedincallback?code=${code}&state=123456`
      );
     
      if (res?.data?.success) {
   
        signInApi({
          email: res?.data?.data?.userinfo?.email,
          logintype: "social",
          method: "linkedin",
          social_id: res?.data?.data?.access_token,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    accessLinkedinInfoAPi();
 
  }, []);

  return (
    <section>
      <div className="content_wrap">
        <PageLoader />
      </div>
    </section>
  );
};

export default LinkedInVerificationPage;


