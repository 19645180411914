import WebLayout from "../../components/weblayout/WebLayout";
import "./OurTeam.scss";
import { images } from "../../utils/images";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { Fragment } from "react";

const OurTeam = () => {
  const { id } = useParams();

  return (
    <WebLayout>
      <section className="p_t p_b our_team">
        <div className="content_wrap">
          {id === "purnima_nambiar" && (
            <Fragment>
              <div className="bi_sec">
                <motion.div
                  initial={{ opacity: 0.4, translateY: 48 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                  className="img_wrap"
                >
                  <img src={images.purnima} alt=" Headshot of Purnima, a woman with black hair in a white sweater sitting on a wicker chair with green foliage in the background." />
                </motion.div>
                <div className="text">
                  <motion.h4
                    initial={{ opacity: 0.4, translateY: 48 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    Purnima Nambiar
                  </motion.h4>

                  <motion.p
                    initial={{ opacity: 0.4, translateY: 48 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    Purnima Nambiar is a highly accomplished and internationally
                    recognized high-performance business and personal coach,
                    mentor, consultant, and facilitator, known for her expertise
                    in driving organizational transformation and empowering
                    individuals to achieve breakthrough results. As the CEO and
                    founder of Inauditus Intervention, Purnima has established
                    herself as a leading authority in driving organizational
                    success and empowering individuals to reach their full
                    potential.
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0.4, translateY: 48 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    As an ICF Certified Coach as well as possessing
                    certifications in EQ-i 2.0, EQ360, Applied Neuroscience for
                    Coaching, and Social Cognitive Neuroscience, Purnima brings
                    a wealth of knowledge and cutting-edge methodologies to her
                    coaching practice. Her diverse skill set enables her to
                    provide holistic support to her clients, helping them unlock
                    their full potential and create meaningful impact in their
                    lives.
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0.4, translateY: 48 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    Purnima's track record speaks for itself. She has conducted
                    numerous workshops in prestigious institutions and corporate
                    settings worldwide, leaving a lasting impact on participants
                    from all walks of life. Her workshops cover a wide spectrum
                    of topics, including strategic vision, business planning,
                    performance management, and talent development. Herpassion
                    lies in creating groundbreaking results and fostering
                    unparalleled success in both the professional and personal
                    lives of her clients. Her unwavering commitment to making a
                    positive difference in people's lives has earned her a
                    reputation as a trusted advisor and mentor.
                  </motion.p>

                  <motion.p
                    initial={{ opacity: 0.4, translateY: 48 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    Her client base reflects the breadth of her expertise,
                    ranging from high-ranking government officials, medical
                    professionals, and corporate executives to business owners,
                    athletes, actors, and academics.
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0.4, translateY: 48 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    She has coached the successful completion of over 1200
                    not-for-profit projects that have directly contributed to
                    bringing about social change across various groups,
                    communities, and organizations.
                  </motion.p>
                </div>
              </div>
            </Fragment>
          )}

          {id === "mahesh_nambiar" && (
            <Fragment>
              <div className="bi_sec">
                <motion.div
                  initial={{ opacity: 0.4, translateY: 48 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                  className="img_wrap"
                >
                  <img src={images.mahesh} alt="Headshot of Mahesh, a middle-aged man smiling against a plain white background." />
                </motion.div>

                <div className="text">
                  <motion.h4
                    initial={{ opacity: 0.4, translateY: 48 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    Mahesh Nambiar
                  </motion.h4>

                  <motion.p
                    initial={{ opacity: 0.4, translateY: 48 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    Mahesh Nambiar is a highly accomplished and internationally
                    recognized coach, mentor, trainer, and consultant who has
                    made a profound impact on the development of his clients,
                    ranging from high-ranking government officials, medical
                    professionals, and corporate executives to business owners,
                    athletes, actors, and academics. With an extensive 28-year
                    track record, Mahesh has emerged as a catalyst for change,
                    leaving an indelible mark on a global scale.
                  </motion.p>

                  <motion.p
                    initial={{ opacity: 0.4, translateY: 48 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    Having touched the lives of over 150,000 individuals in
                    nearly 70 cities worldwide, Mahesh has earned a
                    well-deserved reputation as a luminary in his field. His
                    reflection-oriented coaching methodology has empowered
                    individuals from diverse backgrounds, transcending
                    industries and boundaries. Mahesh's tailor-made and
                    industry-agnostic interventions, consultations, and coaching
                    have led to remarkable breakthroughs and sustainable
                    transformations.
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0.4, translateY: 48 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    By inculcating value- and action-based leadership, he has
                    empowered his clients to achieve unprecedented performance
                    and cultivate emotional intelligence and viable success.
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0.4, translateY: 48 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    Mahesh's dynamic training programs equip individuals and
                    teams with the essential skills, knowledge, and mindset
                    required for success in their respective fields. Whether
                    focusing on leadership development, effective interpersonal
                    relationships, or personal mastery, Mahesh's training
                    sessions are renowned for inspiring engagement and driving
                    tangible results.
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0.4, translateY: 48 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    Beyond coaching and training, Mahesh assumes the role of a
                    trusted advisor and unwavering supporter, guiding his
                    clients through critical decisions and challenging
                    transitions. Countless individuals attribute their personal
                    and professional success to his mentorship, highlighting his
                    pivotal influence in shaping their lives and careers.
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0.4, translateY: 48 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    Mahesh Nambiar is known for his unparalleled expertise, deep
                    compassion, and unwavering commitment to his clients'
                    success. His dedication to fostering leadership development
                    and sustainable success sets him apart as a true leader in
                    his field.
                  </motion.p>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </section>
    </WebLayout>
  );
};

export default OurTeam;
