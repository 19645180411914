import "./CourseModules.scss";
import { images } from "../../../../utils/images";
import { HiLockClosed } from "react-icons/hi";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl, getModulesList } from "../../../../utils/apiData";
import { useState, useEffect, Fragment } from "react";
import Spinner from "react-bootstrap/Spinner";
import { FaCircleCheck } from "react-icons/fa6";

const CourseModules = ({ id }) => {
  const navigate = useNavigate();
  const [moduleList, setModuleList] = useState([]);
  const [loader, setLoader] = useState(false);

  // Get Module List API
  const getModulesApi = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${getModulesList}`,
        { course_id: id },
        { headers }
      );
      if (res?.data?.success) {
        setModuleList(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getModulesApi();
  }, []);

  return (
    <section className="course_module">
      <div className="content_wrap">
        {!loader && <h5>Modules ({moduleList?.length})</h5>}

        {loader ? (
          <div className="loader_card">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        ) : moduleList?.length > 0 ? (
          <Fragment>
            <div className="modules_grid">
              {moduleList?.map((data, index) => {
                return (
                  <motion.div
                    initial={{ scale: 0.8, opacity: 0 }}
                    whileInView={{ scale: 1, opacity: 1 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: (index % 3) * 0.1,
                    }}
                    className="module_card"
                    key={data._id}
                    onClick={() => navigate(`/auth/lessons/${data?._id}`)}
                  >
                    <h5>{data?.module_name || "-"}   {data?.allChaptersCompleted && <FaCircleCheck />}</h5>

                    <div className="img_wrap">
                      {data?.module_image ? (
                        <img src={data?.module_image} alt="module" />
                      ) : (
                        <img src={images.dummyCourse} alt="module" />
                      )}
                    </div>
                    {/* <div className="line">
                      <p className="m-0">{data?.module_subtitle || "-"} </p>
                      {data?.allChaptersCompleted && <FaCircleCheck />}
                    </div> */}
                  </motion.div>
                );
              })}
            </div>
          </Fragment>
        ) : (
          <p className="text_center">Data not found...</p>
        )}
      </div>
    </section>
  );
};

export default CourseModules;
