import "./Intervention.scss";
import WebLayout from "../../components/weblayout/WebLayout";
import InterventionLanding from "./interventionLanding/InterventionLanding";
import KeyAreas from "./keyAreas/KeyAreas";
import CTA from "../../components/cta/CTA";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PageLoader from "../../lms/lmsComponents/pageLoader/PageLoader";
import { baseUrl, invertionPage } from "../../utils/apiData";
import { Fragment, useEffect, useState } from "react";

const Intervention = () => {
  const [pageData, setPageData] = useState({});
  const [loader, setLoader] = useState(false);

  const interventionPageApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${invertionPage}`);
      if (res?.data?.success) {
        setPageData(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    interventionPageApi();
  }, []);

  const navigate = useNavigate();

  return (
    <WebLayout>
      {loader ? (
        <PageLoader />
      ) : (
        <Fragment>
          <InterventionLanding data={pageData} />
          <KeyAreas data={pageData} />
          <CTA
            title="Ready for the Unprecedented?"
            btnText="Get in Touch"
            clickHandler={() => navigate(`/contact`)}
          />
        </Fragment>
      )}
    </WebLayout>
  );
};
export default Intervention;
