import { useEffect } from "react";
import {useNavigate} from "react-router-dom";

const ProtectedRoute = ({children}) => {
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token){      
        navigate("/signin");
    }
  });

  return  children ;
};

export default ProtectedRoute;