import "./DeleteAccount.scss";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { images } from "../../utils/images";
import { motion } from "framer-motion";
import TextError from "../../components/textError/TextError";
import { Fragment, useContext, useState, useEffect } from "react";
import WebLayout from "../../components/weblayout/WebLayout";
import { NavLink, useNavigate } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa";
import { baseUrl, appDeleteAccount } from "../../utils/apiData";
import axios from "axios";
import { webContext } from "../../webContext/WebContext";


const DeleteAccount = () => {
  const { toastClear, toastWarning, toastError, toastInfo, toastSuccess } =
    useContext(webContext);
  const [type, setType] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("invalid email format")
      .required("Email Required"),
    password: Yup.string().required("Password Required"),
  });



  const deleteAccountApi = async (values) => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${appDeleteAccount}`, { ...values });
      console.log(res);
      if (res?.data?.success) {
        setLoader(false);
        localStorage.clear();
        toastSuccess(res?.data?.message || "Account Deleted!");
      } else {
        toastError(res?.data?.message || "Something went wrong!");
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const onSubmit = (values, { resetForm }) => {
    deleteAccountApi(values);
    resetForm();
  };

  return (
    <WebLayout>
      <section className="delete_acc p_b">
        <div className="content_wrap">
          <div className="bi_sec">
            <div className="img_wraper p_t p_b">
              <motion.img
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1, ease: "easeInOut", delay: 0.1 }}
                src={images.loginBanner}
                alt="Illustration of a hand holding a smartphone with an interface displaying user profile and social media icons hovering above the screen."
              />
            </div>
            <div className="form_wraper p_t p_b">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                <Form>
                  <div className="title">
                    <motion.h3
                      initial={{ opacity: 0, translateY: 60 }}
                      whileInView={{ opacity: 1, translateY: 0 }}
                      transition={{ duration: 0.6, ease: "easeInOut" }}
                    >
                      Hi!
                    </motion.h3>

                    <motion.p
                      initial={{ opacity: 0, translateY: 60 }}
                      whileInView={{ opacity: 1, translateY: 0 }}
                      transition={{
                        duration: 0.6,
                        ease: "easeInOut",
                        delay: 0.2,
                      }}
                    >
                      Delete Account
                    </motion.p>
                  </div>

                  <motion.div
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.1,
                    }}
                    className="field_wrap"
                  >
                    <label>Email</label>
                    <Field
                      type="text"
                      placeholder="e.g. email@gmail.com"
                      id="email"
                      name="email"
                      autoComplete="off"
                    />
                    <ErrorMessage name="email" component={TextError} />
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.2,
                    }}
                    className="field_wrap"
                  >
                    <label>Password</label>
                    <div className="password_wrap">
                      <Field
                        type={type ? "text" : "password"}
                        placeholder="Password"
                        id="password"
                        name="password"
                        autoComplete="off"
                      />
                      <button type="button" onClick={() => setType(!type)}>
                        {type ? <FaEyeSlash /> : <IoEyeSharp />}
                      </button>
                    </div>
                    <ErrorMessage name="password" component={TextError} />
                  </motion.div>
                  <motion.p
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.3,
                    }}
                    className="forgot"
                  >
                    <NavLink to="/forgotpassword">Forgot Password?</NavLink>
                  </motion.p>

                  <motion.button
                    initial={{ opacity: 0, translateY: 60 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                      delay: 0.4,
                    }}
                    type="submit"
                    className="submit_btn"
                  >
                    {loader ? "Loading..." : "Delete Account"}
                  </motion.button>
                </Form>
              </Formik>          
        
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
};

export default DeleteAccount;
