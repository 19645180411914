import { useNavigate } from "react-router-dom";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import "./CourseCta.scss";
import {motion} from "framer-motion";

const CourseCta = () => {

const navigate = useNavigate();

  return (
    <section className="course_cta p_t p_b">
      <div className="content_wrap">
        <motion.div 
         initial={{ opacity: 0, translateY: 60, scale:0.6 }}
         whileInView={{ opacity: 1, translateY: 0, scale:1 }}
         transition={{ duration: 0.8, ease: "easeInOut" }}
        className="content_box p_t p_b">
          <motion.h2
           initial={{ opacity: 0, translateY: 60 }}
           whileInView={{ opacity: 1, translateY: 0 }}
           transition={{ duration: 0.6, ease: "easeInOut" }}
          >Ready for the Unprecedented?</motion.h2>
          <PrimaryBtn
            title="Get Started"
            clickHandler={() => navigate("/signin")}
            delay={0.4}
          />
        </motion.div>
      </div>
    </section>
  );
};

export default CourseCta;
