import EmailSubscription from "../emailSubscription/EmailSubscription";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import "./WebLayout.scss";
import WebSideBar from "./webSideBar/WebSideBar";
import { useEffect, useState } from "react";

const WebLayout = ({ children }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // useEffect(() => {
  //   const handleKeyPress = (event) => {
  //     console.log(event);
  //     if ([91, 123].includes(event.keyCode)) {
  //       event.preventDefault();        
  //     }


  //   };
  //   window.addEventListener("keydown", handleKeyPress);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, []);

  return (
    <div className="web_layout">
      <WebSideBar show={show} handleClose={handleClose} />
      <Header handleShow={handleShow} />
      <div className="filler"></div>
      {children}
      <EmailSubscription />
      <Footer />
    </div>
  );
};

export default WebLayout;
