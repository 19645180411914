import "./ScreenTitle.scss";

import { IoChevronBackSharp } from "react-icons/io5";

const ScreenTitle = ({ clickHandler, title }) => {
  return (
    <div className="screen_title">
      <button type="button" className="back" onClick={clickHandler}>
        <IoChevronBackSharp />
      </button>
      <h6>{title}</h6>
    </div>
  );
};

export default ScreenTitle;
