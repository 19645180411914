import {
     BOOKMARK_COURSE_REQUEST,
 BOOKMARK_COURSE_SUCCESS,
 BOOKMARK_COURSE_FAIL,
} from "./const";



export const courseBookmarkReducer = (state={},action)=>{
    switch(action.type){
        case BOOKMARK_COURSE_REQUEST:
            return {loading:true};
        case BOOKMARK_COURSE_SUCCESS:
            return {loading:false, cartData:action.payload}
        case BOOKMARK_COURSE_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state;
    }
}