import "./OfferingSlider.scss";
import Slider from "react-slick";
import { images } from "../../../utils/images";
import { useState } from "react";
import { useRef } from "react";
import { GoArrowUpRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";

const OfferingSlider = () => {
  const [active, setActive] = useState(0);

  const redirectPath = [
    "/offerings/intervention",
    "/offerings/coaching",
    "/offerings/consultancy",
    "/offerings/courses",
  ];

  const slideRef = useRef();
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",

    beforeChange: (current, next) => {
      setActive(next);
    },
  };

  const setSlide = (num) => {
    slideRef.current.slickGoTo(num);
  };

  return (
    <div className="offering_slider">
      <Slider ref={slideRef} {...settings}>
        <div className="offering_slide">
          <div className="content_wrap">       
            <h1 className="big">Intervention</h1>
            <p>
              Inauditus engenders the conditions for organizations to thrive by
              aligning and optimizing their design, culture, operations, and
              performance with impactful interventions.
            </p>
          </div>
          <div className="dark_overlay"></div>
          <img src={images.intervention} alt="Narrow interior space with concrete walls and a glass ceiling, featuring a glass and metal bridge across the center." />
        </div>

        <div className="offering_slide">
          <div className="content_wrap">
            <h1 className="big">Coaching</h1>
            <p>
              Inauditus’ experienced team of coaches is dedicated to empowering
              individuals, teams and organizations to thrive in evolving and
              competitive business environments.
            </p>
          </div>
          <div className="dark_overlay"></div>
          <img src={images.coaching} alt="People seated at a wooden table with notepads and pens, engaged in a discussion. Sunlight filters through a window in the background." />
        </div>

        <div className="offering_slide">
          <div className="content_wrap">
            <h1 className="big">Consultancy</h1>
            <p>
              Inauditus offers a broad range of consultancy services —
              addressing Management, Operations, Organizational Dynamics, and
              Training & Development — designed to empower organizations and
              drive their success.
            </p>
          </div>
          <div className="dark_overlay"></div>
          <img src={images.consultancy} alt="Two people sitting on a bench, engaged in conversation; one is holding a tablet." />
        </div>

        <div className="offering_slide">
          <div className="content_wrap">
            <h1 className="big" >Courses</h1>
            <p>
              Inauditus’ structured & engaging courses facilitate learning,
              development, and upskilling for individuals, institutions, and
              organizations.
            </p>
          </div>
          <div className="dark_overlay"></div>
          <img src={images.courses} alt="Workspace setup with an open laptop, notebook with a pen, a white mug, a small bottle, and flowers in a jar on a wooden table by a window." />
        </div>
      </Slider>
      <div className="dot_line">
        <div className="pill_wraper">
          <button
            type="button"
            className={active == 0 ? "active" : ""}
            onClick={() => setSlide(0)}
          ></button>
          <button
            type="button"
            className={active == 1 ? "active" : ""}
            onClick={() => setSlide(1)}
          ></button>
          <button
            type="button"
            className={active == 2 ? "active" : ""}
            onClick={() => setSlide(2)}
          ></button>
          <button
            type="button"
            className={active == 3 ? "active" : ""}
            onClick={() => setSlide(3)}
          ></button>
        </div>

        <button
          type="button"
          className="redirect"
          onClick={() => navigate(redirectPath[active])}
        >
         Learn more <GoArrowUpRight />
        </button>
      </div>
    </div>
  );
};

export default OfferingSlider;
