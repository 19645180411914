import { Fragment, useContext, useEffect, useState } from "react";
import "./CartSideBar.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import { images } from "../../../utils/images";
import { FaUser } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";
import DeleteProductPopup from "./deleteProductPopup/DeleteProductPopup";
import { useNavigate } from "react-router-dom";
import {
  baseUrl,
  getCart,
  createOrder,
  applyCoupon,
  removeCoupon,
} from "../../../utils/apiData";
import axios from "axios";
import { webContext } from "../../../webContext/WebContext";
import Spinner from "react-bootstrap/Spinner";

const CartSideBar = ({ cartStatus, closeCart }) => {
  const { toastSuccess, toastError, removeCartEffect, addCartEffect } =
    useContext(webContext);

  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [codeLoader, setCodeLoader] = useState(false);

  const [selectedItem, setSelectedItem] = useState();

  const [cartProduct, setCartProduct] = useState([]);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [placeOrderLoader, setPlaceOrderLoader] = useState(false);
  const [coupon, setCoupon] = useState("");

  // Get Cart API

  const getCartApi = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      version: "desktop",
    };
    try {
      const res = await axios.get(`${baseUrl}/${getCart}`, { headers });
      if (res?.data?.success) {
        setCartProduct([...res?.data?.data?.cart]);
        setTax(res?.data?.data?.Tax);
        setTotal(res?.data?.data?.totalPrice);
        setSubTotal(res?.data?.data?.subTotal);
        setDiscount(res?.data?.data?.discount);
        setLoader(false);
        setCoupon(res?.data?.data?.appliedcouponcode);
      }
    } catch (error) {
      // console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    setCartProduct([]);
    getCartApi();
    setSelectedItem();
  }, [addCartEffect, removeCartEffect]);

  const placeOrderApi = async () => {
    setPlaceOrderLoader(true);

    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${createOrder}`,
        {},
        { headers }
      );
      if (res?.data?.success) {
        window.open(res?.data?.data?.url, "_blank", "noopener,noreferrer");
        setPlaceOrderLoader(false);
        toastSuccess(res?.data?.message || "Order Place Success!");
      }
    } catch (error) {
      setPlaceOrderLoader(false);
      console.log(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const PromoCodeEnter = (e) => {
    setCode(e.target.value);
  };

  const applyCodeApi = async () => {
    setCodeLoader(true);
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${applyCoupon}`,
        { coupon: code },
        { headers }
      );
      if (res?.data?.success) {
        setCodeLoader(false);
        toastSuccess(res?.data?.message || "Order Place Success!");
        getCartApi();
        setCode("");
      }
    } catch (error) {
      console.log(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
      setCode("");
      setCodeLoader(false);
    }
  };

  const goCourse = () => {
    closeCart();
    navigate("/auth/courses");
  };

  const removeCoupanApi = async (code) => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${removeCoupon}`,
        { coupon: code },
        { headers }
      );

      if (res?.data?.success) {
        getCartApi();

      }
    } catch (error) {}
  };

  const removeCoupanHandler = (code) => {
    removeCoupanApi(code);
  }

  return (
    <Offcanvas
      show={cartStatus}
      onHide={closeCart}
      placement="end"
      className="cart_sidebar"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h5>Cart </h5>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {loader ? (
          <div className="loader_card pt-5 mt-5">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        ) : cartProduct?.length > 0 ? (
          <Fragment>
            {/* Product List */}
            <div className="cart_items">
              {cartProduct?.map((data) => {
                return (
                  <div className="item_card" key={data._id}>
                    <button
                      type="button"
                      className="delete"
                      onClick={() => {
                        setDeleteShow(true);
                        setSelectedItem(data);
                      }}
                    >
                      <MdDeleteOutline />
                    </button>
                    <div className="img_wrap">
                      {<img src={data?.course_id?.image} alt="course" />}
                    </div>
                    <div className="text">
                      <h6>{data?.course_id?.name}</h6>
                      <div className="name_line">
                        <FaUser /> <p>{data?.course_id?.author_name}</p>
                      </div>
                      <div className="price_line">
                        <h6>₹ {data?.course_id?.price_inr}</h6>
                        <div className="pill">
                          <p>{data?.course_id?.modules_count} Modules</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Promocode */}
            <div className="promocode_wrap">
              <div className="field_wraper">
                {code.length > 0 && (
                  <button type="button" onClick={applyCodeApi}>
                    {codeLoader ? (
                      <Spinner animation="border" role="status"></Spinner>
                    ) : (
                      "Apply"
                    )}
                  </button>
                )}

                <input
                  type="text"
                  placeholder="Enter Promo code"
                  value={code}
                  onChange={PromoCodeEnter}
                />
                
              </div>
            </div>
            {
              coupon && <div className="coupon_card">
                <div className="info">
                <p><b>Coupon :</b> {coupon} </p>
                <p><b>Discount :</b> ₹ {discount} </p>
                </div>
                <button type="button" className="secondary_btn" onClick={() => removeCoupanHandler(coupon)} >Remove</button>
              </div>
            }
            <div className="couoan_card">

            </div>
            {/* Totals */}
            <div className="total_count">
              <div className="total_line">
                <p>Subtotal</p>
                <h6>₹ {subTotal}</h6>
              </div>
              <div className="total_line">
                <p>Taxes</p>
                <h6>₹ {tax}</h6>
              </div>

              {discount > 0 && (
                <div className="total_line">
                  <p>Discount:</p>
                  <h6>- ₹ {discount}</h6>
                </div>
              )}

              <div className="total_line total">
                <p>Total</p>
                <h6>₹ {total}</h6>
              </div>
            </div>
            <button
              type="button"
              className="secondary_btn bottom"
              onClick={() => placeOrderApi()}
            >
              {placeOrderLoader ? "Loading..." : "Place Order"}
            </button>
            {deleteShow && (
              <DeleteProductPopup
                setDeleteShow={setDeleteShow}
                selectedItem={selectedItem}
                getCartApi={getCartApi}
              />
            )}
          </Fragment>
        ) : (
          <div className="empty_cart">
            <p className="text-center">Your Cart is Empty…</p>
            <button className="secondary_btn" onClick={goCourse}>
              Visit Courses
            </button>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CartSideBar;
