import "./ContactForm.scss";
import FieldError from "./fieldError/FieldError";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { images } from "../../../utils/images";
import { motion } from "framer-motion";
import axios from "axios";
import { baseUrl, contact } from "../../../utils/apiData";
import { useContext, useState } from "react";
import { webContext } from "../../../webContext/WebContext";

const ContactForm = () => {
  const [loader, setLoader] = useState(false);
  const { toastSuccess, toastError } = useContext(webContext);

  const contactApi = async (val) => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${contact}`, { val });
      if (res?.data?.success) {
        toastSuccess(res?.data?.message || "Contact Form Submitted!");
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const initialValues = {
    name: "",
    mobile: "",
    email: "",
    company_name: "",
    question: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required!"),
    mobile: Yup.string().required("Number is required!"),
    email: Yup.string()
      .email("Invalid email formate!")
      .required("Email is required!"),
    company_name: Yup.string().required("Company name is required!"),
    question: Yup.string().required("Question is required!"),
  });

  const onSubmit = (values, { resetForm }) => {
    contactApi(values);
    resetForm();
  };

  return (
    <section className="contact_form p_t p_b">
      <div className="content_wrap">
        <div className="bi_grid">
          <div className="form_wrap">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <h2 className="wow fadeInUp">Get in Touch</h2>
                <Field
                  type="text"
                  placeholder="Name"
                  className="wow fadeInUp"
                  data-wow-delay="0.1s"
                  name="name"
                  id="name"
                  autoComplete="off"
                />
                <ErrorMessage name="name" component={FieldError} />
                <Field
                  type="number"
                  placeholder="Phone Number"
                  className="wow fadeInUp"
                  data-wow-delay="0.2s"
                  name="mobile"
                  id="mobile"
                  autoComplete="off"
                />
                <ErrorMessage name="mobile" component={FieldError} />
                <Field
                  type="email"
                  placeholder="Email"
                  className="wow fadeInUp"
                  data-wow-delay="0.3s"
                  name="email"
                  id="email"
                  autoComplete="off"
                />
                <ErrorMessage name="email" component={FieldError} />
                <Field
                  type="text"
                  placeholder="Company Name"
                  className="wow fadeInUp"
                  data-wow-delay="0.4s"
                  name="company_name"
                  id="company_name"
                  autoComplete="off"
                />
                <ErrorMessage name="company_name" component={FieldError} />
                <Field
                  as="textarea"
                  placeholder="Query"
                  className="wow fadeInUp"
                  data-wow-delay="0.5s"
                  name="question"
                  id="question"
                  autoComplete="off"
                />
                <ErrorMessage name="question" component={FieldError} />
                <button type="submit" className="secondary_btn">
                  {loader ? "Loading..." : "Submit"}
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
