import Counter from "../../../components/counter/Counter";
import "./CourseCounter.scss";
import { motion } from "framer-motion";

const CourseCounter = ({ detail }) => {
  return (
    <section className="course_counter p_b">
      <div className="content_wrap">
        <div className="title  p_b">
          <motion.h2
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className="mid"
          >
            {detail?.sec_1_description}
          </motion.h2>
        </div>
        <div className="counter_grid">
          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className="counter_card a"
          >
            <h1>
              <span>
                <Counter count={Number.parseInt(detail?.sec_1_percentage)} />
              </span>
              %
            </h1>
            <p>{detail?.sec_1_title || "-"}</p>
            <p className="m_t">
              <b>{detail?.sec_1_description || "-"}</b>
            </p>
            <p>{detail?.sec_1_location || "-"}</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className="counter_card b"
          >
            <h1>
              <span>
                <Counter count={Number.parseInt(detail?.sec_2_percentage)} />
              </span>
              %
            </h1>
            <p>{detail?.sec_2_title || "-"}</p>
            <p className="m_t">
              <b>{detail?.sec_2_description || "-"}</b>
            </p>
            <p>{detail?.sec_2_location || "-"}</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className="counter_card c"
          >
            <h1>
              <span>
                <Counter count={Number.parseInt(detail?.sec_3_percentage)} />
              </span>
              %
            </h1>
            <p>{detail?.sec_3_title || "-"}</p>
            <p className="m_t">
              <b>{detail?.sec_3_description || "-"}</b>
            </p>
            <p>{detail?.sec_3_location || "-"}</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
export default CourseCounter;
