import _ from "lodash";
import Alert from "react-s-alert";
import "react-toastify/dist/ReactToastify.css";
import { Component, Fragment, useEffect } from "react";
import { FaLinkedinIn } from "react-icons/fa";

class LinkedinAuthBtn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthorized: false,
      firstName: null,
      lastName: null,
      profileURL: null,
      pictureURL: null,
    };
  }

  componentDidMount() {
    window.addEventListener("message", this.handlePostMessage);
  }

  handlePostMessage = (event) => {
    if (event.data.type === "profile") {
      this.updateProfile(event.data.profile);
      Alert.success(
        `Login successful: ${event.data.profile.localizedFirstName}`,
        { position: "top" }
      );
    }
  };



  requestProfile = () => {

    var oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&scope=openid%20profile%20email&state=123456&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;

    // var oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&scope=profile&state=123456&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;

    var width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;

    window.open(
      oauthUrl,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  render() {
    return (
      <Fragment>
        <button
          type="button"
          className="alternate_btn"
          onClick={this.requestProfile}
        >
          <FaLinkedinIn />
        </button>
      </Fragment>
    );
  }
}

export default LinkedinAuthBtn;
