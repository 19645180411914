import { images } from "../../../utils/images";
import "./CoachingDetail.scss";
import { motion } from "framer-motion";

const CoachingDetail = ({data}) => {
  return (
    <section className="coaching_grid">
      <div className="content_wrap">
        <div className="career_grid">
          <div className="white_card">
            <motion.h2
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              We Offer
            </motion.h2>

      
          </div>

          <div className="info_card">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="num_box a"
            >
              <p>
                <b>01</b>
              </p>
            </motion.div>

            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
             {data?.section_1_title || "-"}
            </motion.h4>
         
          </div>

          <div className="info_card">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="num_box b"
            >
              <p>
                <b>02</b>
              </p>
            </motion.div>

            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              {data?.section_2_title || "-"}
            </motion.h4>
     
          </div>

          <div className="text_card">
            <img src={images.careerArrow} alt="text" className="arrow" />
          </div>
          <div className="info_card">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="num_box c"
            >
              <p>
                <b>03</b>
              </p>
            </motion.div>

            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
            {data?.section_3_title || "-"}
            </motion.h4>
           
          </div>

          <div className="info_card">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="num_box d"
            >
              <p>
                <b>04</b>
              </p>
            </motion.div>

            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
             {data?.section_4_title || "-"}
            </motion.h4>
           
          </div>
          <div className="emptya"></div>
          <div className="info_card">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="num_box a"
            >
              <p>
                <b>05</b>
              </p>
            </motion.div>

            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              {data?.section_5_title || "-"}
            </motion.h4>
           
          </div>
          <div className="info_card">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="num_box b"
            >
              <p>
                <b>06</b>
              </p>
            </motion.div>

            <motion.h4
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
            {data?.section_6_title || "-"}
            </motion.h4>
           
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoachingDetail;
