import "./OfferingSecond.scss";
import {images} from "../../../utils/images";

import {motion} from "framer-motion";

const OfferingSecond = () => {
    return(
        <section className="offering_second p_t p_b">
            <div className="content_wrap">
                <div className="bi_sec">
                    <div className="img_wrap">
                        <motion.img 
                                        initial={{ opacity: 0, scale: 0 }}
                                        whileInView={{ opacity: 1, scale: 1 }}
                                        transition={{ duration: 1, ease: "easeInOut" }}
                        src={images.offeringA} alt="offering" />
                    </div>
                    <div className="text_wrape">                        
                        <motion.p 
                                        initial={{ opacity: 0, scale: 0 }}
                                        whileInView={{ opacity: 1, scale: 1 }}
                                        transition={{ duration: 1, ease: "easeInOut", delay: 0.1 }}
                        >Our offerings have been developed after extensive research on leaders in high-stakes situations and aim to better equip leaders to produce unprecedented performance.</motion.p>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OfferingSecond;