import "./LmsHomeSubjects.scss";
import { images } from "../../../utils/images";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const LmsHomeSubjects = ({ category }) => {
  
  const navigate = useNavigate();

  return (
    <section className="lms_subjects">
      <div className="content_wrap">
        <div className="title_wrap">
        <motion.h3
          initial={{ translateY: 48, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          Areas
        </motion.h3>

        <button type="button" className="view_more_btn" onClick={() => navigate("/auth/allcourses")} >View More</button>
        </div>
        {category?.length > 0 ? (
          <div className="subject_grid">
            {category.slice(0,4)?.map((data, j) => {
              return (
                <motion.div
                  initial={{ translateY: 48, scaleX: 0.5, opacity: 0 }}
                  whileInView={{ translateY: 0, scaleX: 1, opacity: 1 }}
                  transition={{
                    duration: 0.6,
                    ease: "easeInOut",
                    delay: 0.1 * j,
                  }}
                  className="subject"
                  key={data._id}
                  onClick={() => navigate(`/auth/courses/${data?._id}`)}
                >
                  <h4>{data.name}</h4>
                  <img src={data?.image} alt="category" />
                </motion.div>
              );
            })}
          </div>
        ) : (
          <p className="text-center mt-5">Category not found...</p>
        )}
      </div>
    </section>
  );
};

export default LmsHomeSubjects;
