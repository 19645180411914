import "./ViewBlogLanding.scss";
import TextPill from "../../../components/textPill/TextPill";
import { images } from "../../../utils/images";

const ViewBlogLanding = ({blog}) => {

  const formatedDate = (dateString) => {
    var dateObject = new Date(dateString);

    // Format the Date object
    var options = { year: 'numeric', month: 'short', day: '2-digit' };
    var formattedDate = dateObject.toLocaleDateString('en-US', options);
    return formattedDate;
  }



  return (
    <section className="view_blog_landing">
      <div className="content_wrap p_t">
        <div className="bi_part">
          <div className="left">
            
            <TextPill title="Category" theme="secondary" />
            <h2>{blog?.name || "-"}</h2>
            <p className="t_18">
              {blog?.subtitle || "-"}
            </p>

            <p className="date">By The {blog?.author || "-"}</p>
            <p>{formatedDate(blog?.updatedAt) || "-"}</p>
          </div>
          <div className="img_wraper">
            <img src={blog?.image} alt="post" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewBlogLanding;
