import { CgNotes } from "react-icons/cg";

const DescriptionSec = ({ para }) => {
  return (
    <div className="description_sec">
      <h5>
        <CgNotes /> Description
      </h5>
      <p>{para || "-"}</p>
    </div>
  );
};

export default DescriptionSec;
