import { Fragment, useContext, useState } from "react";
import "./CourseDetailInfo.scss";
import { FaStar } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaVideo } from "react-icons/fa";
import { PiCertificateFill } from "react-icons/pi";
import CourseOverview from "./courseOverview/CourseOverview";
import CourseModules from "./courseModules/CourseModules";
import { motion } from "framer-motion";
import Certificate from "./certificate/Certificate";
import { webContext } from "../../../webContext/WebContext";

const CourseDetailInfo = ({ courseDetail, id }) => {
  const { asModule } = useContext(webContext);

  const [btn, setBtn] = useState(asModule ? 1 : 0);

  return (
    <Fragment>
      <section className="course_info">
        <div className="content_wrap">
          <div className="name_line">
            <motion.h3
              initial={{ opacity: 0, translateY: 48 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              {courseDetail?.name || "-"}
            </motion.h3>
            {/* <motion.div
              initial={{ opacity: 0, translateY: 48 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
              className="rating"
            >
              <FaStar /> <p>{courseDetail?.ratings || 0} Ratings</p>
            </motion.div> */}
          </div>
          <div className="info_line">
            {/* <motion.div
              initial={{ opacity: 0, translateX: 48 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="info"
            >
              <FaUser />
              <h5>{courseDetail?.author_name || "-"}</h5>
            </motion.div> */}
            <motion.div
              initial={{ opacity: 0, translateX: 48 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.1 }}
              className="info"
            >
              <FaVideo />
              <h5>{courseDetail?.modules_count || "0"} Modules</h5>
            </motion.div>

            {/* <motion.div
              initial={{ opacity: 0, translateX: 48 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
              className="info"
            >
              <PiCertificateFill />
              <h5>Certificate</h5>
            </motion.div> */}
          </div>
          <div className="btns_wrapers">
            <button
              type="button"
              className={btn === 0 ? "active" : ""}
              onClick={() => setBtn(0)}
            >
              Overview
            </button>
            <button
              type="button"
              className={btn === 1 ? "active" : ""}
              onClick={() => setBtn(1)}
            >
              Modules
            </button>

            {courseDetail?.is_certificate &&
              courseDetail?.user_status === "completed" && (
                <button
                  type="button"
                  className={btn === 2 ? "active" : ""}
                  onClick={() => setBtn(2)}
                >
                  Certificate
                </button>
              )}

            {/* <button
                type="button"
                className={btn === 2 ? "active" : ""}
                onClick={() => setBtn(2)}
              >
                Certificate
              </button> */}
          </div>
        </div>
      </section>
      {btn === 0 && <CourseOverview courseDetail={courseDetail} />}
      {btn === 1 && <CourseModules id={id} />}
      {btn === 2 && <Certificate />}
    </Fragment>
  );
};
export default CourseDetailInfo;
