import { images } from "../../../utils/images";
import "./QuizeResultModal.scss";
import Modal from "react-bootstrap/Modal";
import { AiFillQuestionCircle } from "react-icons/ai";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoCloseCircleSharp } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const QuizeResultModal = (props) => {
  const navigate = useNavigate();

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="quize_result"
    >
      <Modal.Body>
        <img src={images.childSketch} alt="sketch" />

        <div className="score_bord">
          <div className="info_box a">
            <AiFillQuestionCircle />
            <div className="text">
              <h5>{props?.quizResult?.totalQuestions || "0"}</h5>
              <p>Total Que</p>
            </div>
          </div>

          <div className="info_box b">
           
            <IoCheckmarkCircle />
            <div className="text">
              <h5>{props?.quizResult?.totalCorrectAnswers || "0"}</h5>
              <p>Correct</p>
            </div>
          </div>

          <div className="info_box c">
          <IoCloseCircleSharp />
            <div className="text">
              <h5>{props?.quizResult?.totalWrongAnswers || "0"}</h5>
              <p>Wrong</p>
            </div>
          </div>
        </div>
        <div className="go_chapter_btn">
          <button
            type="button"
            className="secondary_btn"
            onClick={() => {
              navigate(`/auth/chapter/${props?.id}`);
            }}
          >
            Go to Chapter
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QuizeResultModal;
