import "./CtaInfo.scss";
import { HiArrowNarrowRight } from "react-icons/hi";
import { SiInstagram } from "react-icons/si";
import { BsTwitterX } from "react-icons/bs";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { webContext } from "../../../webContext/WebContext";
import axios from "axios";
import { baseUrl, emailSubscription } from "../../../utils/apiData";


import { FaLinkedinIn } from "react-icons/fa";

const CtaInfo = () => {
  const [emailError, setEmailError] = useState("");
  const [mail, setMail] = useState("");
  const [loader, setLoader] = useState("");

  const { toastSuccess, toastError } = useContext(webContext);

  const emailSubscribeApi = async () => {
    const Emailregwx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail === 0) {
      setEmailError("Field is required");
    } else if (!Emailregwx.test(mail)) {
      setEmailError("Invalid Email!");
    } else {
      setLoader(true);
      try {
        const res = await axios.post(`${baseUrl}/${emailSubscription}`, {
          email: mail,
        });

        if (res?.data?.success) {
          toastSuccess(res?.data?.message);
          setMail("");
          setEmailError("");
          setLoader(false);
        }
      } catch (error) {
        console.log(error);
        toastError(error?.response?.data?.message || "Something went wrong!");
        setLoader(false);
      }
    }
  };

  const inputHandler = (e) => {
    const Emailregwx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (e.target.value.length === 0) {
      setEmailError("Field is required");
    } else if (!Emailregwx.test(e.target.value)) {
      setEmailError("Invalid Email!");
    } else {
      setEmailError("");
    }

    setMail(e.target.value);
  };

  const focusHandler = (e) => {
    setEmailError("");
  };

  const blurHandler = (e) => {
    if (e.target.value.length === 0) {
      setEmailError("Field is required");
    } else {
      setEmailError("");
    }
  };

  return (
    <section className="blog_cta p_b">
      <div className="content_wrap">
       
        <div className="info_col mid">
          <motion.h4
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            Let’s take this to your inbox
          </motion.h4>
          <motion.p
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.1 }}
          >
            Subscribe for updates on our offerings, client success stories, and
            the latest insights.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            className="email_box"
          >
            <div className="input_line">
            <input
              type="email"
              placeholder="Email address"
              value={mail}
              onChange={inputHandler}
              onFocus={focusHandler}
              onBlur={blurHandler}
            />
            <PrimaryBtn
              title={loader ? "Loading..." : "I'm in"}
              clickHandler={emailSubscribeApi}
              delay={0.4}
            />
            </div>
            {emailError && <p className="error">{emailError}</p>}
          </motion.div>
        </div>
        <div className="info_col">
          <motion.h4
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            Follow us
          </motion.h4>
          <motion.a
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.1 }}
            href="https://x.com/InauditusI"
            target="_blank"
          >
            <span className="icon">
              <BsTwitterX /> Twitter
            </span>
            <span className="arrow">
              <HiArrowNarrowRight />
            </span>
          </motion.a>
          <motion.a
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            href="https://in.linkedin.com/company/inauditus-intervention"
            target="_blank"
          >
            <span className="icon">
              <FaLinkedinIn /> Linkedin
            </span>
            <span className="arrow">
              <HiArrowNarrowRight />
            </span>
          </motion.a>
        </div>
      </div>
    </section>
  );
};

export default CtaInfo;
