import { useNavigate } from "react-router-dom";
import CTA from "../../components/cta/CTA";
import Offering from "../../components/offering/Offering";
import WebLayout from "../../components/weblayout/WebLayout";
import OfferingSecond from "./offeringSecond/OfferingSecond";
import OfferingSlider from "./offeringSlider/OfferingSlider";
import OfferingThird from "./offeringThird/OfferingThird";

const Offerings = () => {
  const navigate = useNavigate();
  return (
    <WebLayout>
      <OfferingSlider />
      <OfferingSecond />
      <OfferingThird />
      <CTA title="Ready for the Unprecedented?" btnText="Get in Touch" clickHandler={() => navigate(`/contact`)}  /> 
    </WebLayout>
  );
};

export default Offerings;
