import "./PageLoader.scss";
import Spinner from 'react-bootstrap/Spinner';

const PageLoader = () => {
    return(
        <div className="page_loader">
    <Spinner animation="border" role="status">
    </Spinner>
        </div>
    )
}

export default PageLoader;