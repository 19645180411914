import "./CourseCard.scss";
import { IoPerson } from "react-icons/io5";
import { motion } from "framer-motion";
import { IoBookmarkOutline } from "react-icons/io5";
import { GoBookmarkFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { images } from "../../../utils/images";
import { IoMdCart } from "react-icons/io";
import { baseUrl } from "../../../utils/apiData";

const CourseCard = (props) => {
  const navigate = useNavigate();

  return (
    <motion.div 
      initial={{ scale: 0.8, opacity: 0 }}
      whileInView={{ scale: 1, opacity: 1 }}
      transition={{
        duration: 0.6,
        ease: "easeInOut",
        delay: (props.index % 3) * 0.1,
      }}
      className="course_card"
      onClick={() => navigate(`/auth/course/${props._id}`)}
    >
      <div className="img_wrap">
        { <img src={props?.image} alt="course" />}
      </div>
      <div className="info">
        <h4>{props?.name}</h4>
       
        <div className="trainer">
          <IoPerson /> <p>{props?.author_name}</p>
        </div>
        {!props.ongoing ? (
          <div className="prise">
            <div className="left">
              <h5>&#x20B9; {props?.price_inr}</h5>
              <div className="pill">
                <p>{props?.modules_count} Modules</p>
              </div>
            </div>
            <div className="btns">
              {props?.cartBtn && (
                <button
                  type="button"
                  className="filled cart"
                  onClick={props?.addCartHandler}
                >
                  <IoMdCart />
                </button>
              )}
              {props.bookmark && (
                <button
                  type="button"
                  className={props?.filledBookmark ? "filled" : ""}
                  onClick={props.bookmarkHandler}
                >
                  {props?.filledBookmark ? (
                    <GoBookmarkFill />
                  ) : (
                    <IoBookmarkOutline />
                  )}
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="progress_info">
            <div className="progress">
              <div className="filler" style={{width:`${(props?.total_completed_chapters*100)/props?.total_chapters}%`}}></div>
            </div>
            <p>{props?.total_completed_chapters}/{props?.total_chapters}</p>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default CourseCard;
