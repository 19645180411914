import "./LatestPopularTab.scss";

const LatestPopularTab = ({ tab, setTab }) => {
  return (
    <div className="tab_wraper">
      <button
        type="button"
        className={tab === "popular" ? "active" : ""}
        onClick={() => setTab("popular")}
      >
        Popular
      </button>
      <button
        type="button"
        className={tab === "latest" ? "active" : ""}
        onClick={() => setTab("latest")}
      >
        Latest
      </button>
    </div>
  );
};

export default LatestPopularTab;
