import TextPill from "../../../components/textPill/TextPill";
import "./Impact.scss";
import { motion } from "framer-motion";

const Impact = ({ about }) => {
  return (
    <section className="impact_sec p_b">
      <div className="content_wrap">
        <div className="title p_b">
          <motion.h2
            initial={{ opacity: 0, translateY: 60 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            Our Impact
          </motion.h2>
        </div>
        <div className="three_grid">
          <div className="card_wrap a">
            <div className="pill_line">
              <TextPill
                title={about?.impact_1_category || "-"}
                theme="#49BDB6"
              />
            </div>
            <h3>{about?.impact_1_heading || "-"}</h3>
            <h4>{about?.impact_1_title || "-"}</h4>
          </div>

          <div className="card_wrap b">
            <div className="pill_line">
              <TextPill
                title={about?.impact_2_category || "-"}
                theme="#206583"
              />
            </div>
            <h3>{about?.impact_2_heading || "-"}</h3>
            <h4>{about?.impact_2_title || "-"}</h4>
          </div>

          <div className="card_wrap c">
            <div className="pill_line">
              <TextPill
                title={about?.impact_3_category || "-"}
                theme="#9BA2FF"
              />
            </div>
            <h3>{about?.impact_3_heading || "-"}</h3>
            <h4>{about?.impact_3_title || "-"}</h4>
          </div>

          {/* <div className="differe">
            <div className="left">
              <p>
                <b>
                  Kari Franchini <br />
                  Principal
                </b>
              </p>
              <p>Creekside Middle School Ohio, USA</p>
            </div>
            <h4>
              “83% of our kids are doing exactly what they are supposed to.
              Every. Single. Day.”
            </h4>
          </div> */}

          <div className="card_wrap d">
            <div className="pill_line">
              <TextPill
                title={about?.impact_4_category || "-"}
                theme="#8B2635"
              />
            </div>
            <h3>{about?.impact_4_heading || "-"}</h3>
            <h4>{about?.impact_4_title || "-"}</h4>
          </div>

          <div className="card_wrap e">
            <div className="pill_line">
              <TextPill
                title={about?.impact_5_category || "-"}
                theme="#A14DA0"
              />
            </div>
            <h3>{about?.impact_5_heading || "-"}</h3>
            <h4>{about?.impact_5_title || "-"}</h4>
          </div>

          <div className="card_wrap f">
            <div className="pill_line">
              <TextPill
                title={about?.impact_6_category || "-"}
                theme="#8A4F7D"
              />
            </div>
            <h3>{about?.impact_6_heading || "-"}</h3>
            <h4>{about?.impact_6_title || "-"}</h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Impact;
