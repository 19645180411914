import "./NotificationDd.scss";
import { images } from "../../../../utils/images";
import { SlBell } from "react-icons/sl";
import Dropdown from "react-bootstrap/Dropdown";
import { baseUrl, getnotification } from "../../../../utils/apiData";
import axios from "axios";
import { useContext, useEffect } from "react";
import { useState } from "react";
import {webContext} from "../../../../webContext/WebContext";

const NotificationDd = () => {
  const [notifications, setNotifications] = useState([]);
  const {logOutApi} = useContext(webContext);
  const dateFormate = (dateString) => {
    const inputDate = new Date(dateString);

    // Format the date to the desired output format
    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    const outputDateStr = inputDate.toLocaleString("en-US", options);

    return outputDateStr;
  };

  const getNotifications = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.get(`${baseUrl}/${getnotification}`, { headers });
      setNotifications(res?.data?.data);
    } catch (error) {
      console.log(error);
      if(error?.response?.status == 401){
        logOutApi();
      }
    }
  };
  useEffect(() => {
    getNotifications();

    const intervalId = setInterval(() => {
      getNotifications();
    }, 6000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic" className="active">
        <SlBell />
      </Dropdown.Toggle>
      <Dropdown.Menu className="notification">
        {notifications?.length > 0 ? (
          notifications?.map((data) => {
            return (
              <div className="notification_card" key={data._id}>
                <div className="text">
                  <p className="date text-right">
                    {dateFormate(data?.updatedAt)}
                  </p>

                  <div dangerouslySetInnerHTML={{ __html: data?.name }}></div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="no_notify">
            <p>No notifications</p>

            <p className="t_14 mt-2">
             You currently have no notifications.             
            </p>
            <img src={images.emptyNotification} alt="notification" />
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDd;
