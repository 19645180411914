import ScreenTitle from "../screenTitle/ScreenTitle";
import "./NotificationSetting.scss";
import { motion } from "framer-motion";

const NotificationSetting = ({ setScreen }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scaleY: 0.4 }}
      whileInView={{ opacity: 1, scaleY: 1 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      className="notification_setting"
    >
      <ScreenTitle
        title="Email Notification"
        clickHandler={() => setScreen(0)}
      />
      <div className="notify_setting_wraper">
        <div className="check_line">
          <input type="checkBox" />
          <p>Course Reminders </p>
        </div>
        <div className="check_line">
          <input type="checkBox" />
          <p>Weekly personalised course recommendations </p>
        </div>
        <div className="check_line">
          <input type="checkBox" />
          <p>Weekly notifications about promotions and live events </p>
        </div>
      </div>
      <button type="button" className="secondary_btn">
        Update
      </button>
    </motion.div>
  );
};
export default NotificationSetting;
