import { Fragment, useContext, useState, useEffect } from "react";
import LmsLayout from "../lmsComponents/lmsLayout/LmsLayout";
import "./LmsBookmark.scss";
import CourseCard from "../lmsComponents/courseCard/CourseCard";
import { images } from "../../utils/images";
import RemoveBookmarkModal from "./removeBookmarkModal/RemoveBookmarkModal";
import {
  baseUrl,
  bookmarkCategory,
  getBookmarks,
  addToCart,
} from "../../utils/apiData";
import axios from "axios";
import { webContext } from "../../webContext/WebContext";
import Spinner from "react-bootstrap/Spinner";

const LmsBookmark = () => {
  const [selectedCourse, setSelectedCourse] = useState();

  const {logOutApi} = useContext(webContext);

  const {
    toastSuccess,
    setAddCartEffect,
    toastError,
    addCartEffect,
    removeCartEffect,
    bookMarkEffect
  } = useContext(webContext);
  const [loader, setLoader] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [bookmarkList, setBookmarkList] = useState([]);
  const [catLoader, setCatLoader] = useState(false);
  const [filter, setFilter] = useState("");

  const [show, setShow] = useState(false);

  // Categories Api
  const getCategoriesApi = async () => {
    setCatLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${bookmarkCategory}`);
      if (res?.data?.success) {
        setCatLoader(false);
        setCategoryList(res?.data?.data);
      }
    } catch (error) {
      setCatLoader(false);
      console.log(error);
      if(error?.response?.status == 401){
        logOutApi();
      }
    }
  };


  // Get Bookmark Courses API
  const getBookmarkApi = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${getBookmarks}`,
        { categoryId: filter },
        { headers }
      );
      if (res?.data?.success) {
        setLoader(false);
        setBookmarkList(res?.data?.data?.courses);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
      if(error?.response?.status == 401){
        logOutApi();
      }
    }
  };

  useEffect(() => {
    getCategoriesApi();
  }, []);

  useEffect(() => {
    getBookmarkApi();
  }, [filter, addCartEffect, removeCartEffect, bookMarkEffect]);

  const bookmarkHandler = (e, data) => {
    e.stopPropagation();
    setSelectedCourse(data);
    setShow(true);
  };

  // Add To Cart API
  const addToCartApi = async (courseId) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${addToCart}`,
        { course_id: courseId },
        { headers }
      );

      if (res?.data?.success) {
        toastSuccess(res?.data?.message);
        setAddCartEffect((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
      if(error?.response?.status == 401){
        logOutApi();
      }
    }
  };

  const addToCartHandler = (e, data) => {
    e.stopPropagation();
    addToCartApi(data?._id);
  };

  return (
    <LmsLayout>
      <section className="bookmarks">
        <div className="content_wrap">
          <div className="filter_pils">
            {catLoader ? (
              <p>Categories is Loading...</p>
            ) : categoryList?.length > 0 ? (
              <Fragment>
                <div
                  className={`pill ${filter === "" ? "active" : ""}`}
                  onClick={() => setFilter("")}
                >
                  <p>All</p>
                </div>
                {categoryList.map((data) => {
                  return (
                    <div
                      className={`pill ${filter === data?._id ? "active" : ""}`}
                      key={data._id}
                      onClick={() => setFilter(data?._id)}
                    >
                      <p>{data.name}</p>
                    </div>
                  );
                })}
              </Fragment>
            ) : (
              <p>Category not found...</p>
            )}
          </div>

          {loader ? (
            <div className="loader_card">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : (
            <Fragment>
              {bookmarkList?.length > 0 ? (
                <div className="course_grid">
                  {bookmarkList.map((data, j) => {
                    return (
                      <Fragment key={data._id}>
                        <CourseCard
                          {...data}
                          index={j}
                          // cartBtn={!data?.is_cart}
                          cartBtn={false}
                          bookmark={true}
                          filledBookmark={true}
                          ongoing={false}
                          bookmarkHandler={(e) => bookmarkHandler(e, data)}
                          addCartHandler={(e) => addToCartHandler(e, data)}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              ) : (
                <p className="text-center my-5">Nothing to See Here…</p>
              )}
            </Fragment>
          )}
        </div>
      </section>
      <RemoveBookmarkModal
        show={show}
        data={selectedCourse}
        onHide={() => setShow(false)}
        recallApi={getBookmarkApi}
      />
    </LmsLayout>
  );
};
export default LmsBookmark;
