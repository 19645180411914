import { BsFillQuestionOctagonFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const QuizSec = ({ id, isQuizUploaded }) => {
  const navigate = useNavigate();

  return (
    <div className="quize_section">
      <h5>
        <BsFillQuestionOctagonFill /> Quiz
      </h5>
      {isQuizUploaded ? (
        <p>Quiz Submitted Successfully!</p>
      ) : (
        <button
          type="button"
          className="secondary_btn"
          onClick={() => navigate(`/auth/quiz/${id}`)}
        >
          Take Quiz
        </button>
      )}
    </div>
  );
};

export default QuizSec;
