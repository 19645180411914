import { useContext, useEffect, useState } from "react";
import LmsLayout from "../lmsComponents/lmsLayout/LmsLayout";
import "./LmsQuize.scss";
import { MdCheck } from "react-icons/md";

import { IoArrowBackSharp } from "react-icons/io5";
import { IoArrowForward } from "react-icons/io5";
import { FiClock } from "react-icons/fi";
import QuizeResultModal from "./quizeResultModal/QuizeResultModal";

import {
  baseUrl,
  getQuiz,
  submitQuiz,
  quizReport,
  quizTime,
} from "../../utils/apiData";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PageLoader from "../lmsComponents/pageLoader/PageLoader";
import { webContext } from "../../webContext/WebContext";

const LmsQuize = () => {
  const { toastError, toastSuccess } = useContext(webContext);
  const [loader, setLoader] = useState(false);
  const [num, setNum] = useState(1);
  const [show, setShow] = useState(false);
  const [quizList, setQuizList] = useState([]);
  const [quizResult, setQuizResult] = useState({});
  const [time, setTime] = useState();

  const { id } = useParams();

  // Get Quiz API
  const getQuizApi = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${getQuiz}`,
        { chapter_id: id },
        { headers }
      );
      if (res?.data?.success) {
        setLoader(false);
        setQuizList(res?.data?.data);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  // Get Report API
  const getReport = async () => {
    setLoader(true);

    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.get(`${baseUrl}/${quizReport}/${id}`, {
        headers,
      });
      if (res?.data?.success) {
        setShow(true);
        setLoader(false);
        setQuizResult(res?.data?.data);
        toastSuccess(res?.data?.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const backward = () => {
    setNum((prev) => {
      if (prev == 1) {
        return prev;
      } else {
        return prev - 1;
      }
    });
  };

  const forward = () => {
    setNum((prev) => {
      console.log(prev);

      if (prev === quizList?.length) {
        getReport();

        return prev;
      } else {
        return prev + 1;
      }
    });
  };

  const submitQuizApi = async (answer) => {
    setLoader(true);

    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${submitQuiz}`,
        {
          chapter_input_id: quizList[num - 1]?._id,
          selectedOptions: answer,
        },
        { headers }
      );
      console.log("quize submit", res);
      if (res?.data?.success) {
        forward();
        setLoader(false);
        toastSuccess(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  // getTime
  const getTimeAPi = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${quizTime}`,
        { chapter_id: id },
        { headers }
      );
      if (res?.data?.data) {
        setTime(res?.data?.data?.quizz_time * 60);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTimeAPi();
    getQuizApi();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prev) => {
        if (prev === 0) {
          getReport();
          clearInterval(intervalId);

          return 0;
        } else {
          return prev - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <LmsLayout>
      {loader ? (
        <PageLoader />
      ) : (
        <section className="quize">
          <div className="content_wrap">
            <div className="time_line">
              <button className="back">
                <IoArrowBackSharp />
              </button>
              <FiClock />
              <p>
                {Math.floor(time / 3600)} : {Math.floor((time % 3600) / 60)} :{" "}
                {Math.floor(time % 60)}
              </p>
            </div>
            {/* Question Counts */}
            <div className="btn_wrap">
              {quizList.map((data, j) => {
                return (
                  <button
                    type="button"
                    className={`
                    ${j + 1 < num ? "passed" : ""}
                    ${j + 1 === num ? "current" : ""}
                  `}
                    key={data._id}
                  >
                    {j + 1 < num ? <MdCheck /> : j + 1}
                  </button>
                );
              })}
            </div>

            {quizList?.length > 0 ? (
              quizList.map((data, i) => {
                if (i + 1 === num) {
                  return (
                    <div className="question_wrap" key={data._id}>
                      <h4>
                        {num}. <p>{data?.quizz_question || "-"}</p>
                      </h4>

                      <div className="options">
                        {data?.quizz_opt_1 && (
                          <button
                            type="button"
                            className="option"
                            onClick={() => submitQuizApi("quizz_opt_1")}
                          >
                            {data?.quizz_opt_1}
                          </button>
                        )}

                        {data?.quizz_opt_2 && (
                          <button
                            type="button"
                            className="option"
                            onClick={() => submitQuizApi("quizz_opt_2")}
                          >
                            {data?.quizz_opt_2}
                          </button>
                        )}

                        {data?.quizz_opt_3 && (
                          <button
                            type="button"
                            className="option"
                            onClick={() => submitQuizApi("quizz_opt_3")}
                          >
                            {data?.quizz_opt_3}
                          </button>
                        )}

                        {data?.quizz_opt_4 && (
                          <button
                            type="button"
                            className="option"
                            onClick={() => submitQuizApi("quizz_opt_4")}
                          >
                            {data?.quizz_opt_4}
                          </button>
                        )}

                        {data?.quizz_opt_5 && (
                          <button
                            type="button"
                            className="option"
                            onClick={() => submitQuizApi("quizz_opt_5")}
                          >
                            {data?.quizz_opt_5}
                          </button>
                        )}
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <p className="mt-5 text-center">Quiz not found...</p>
            )}

            {/* Question Navigations */}
            <div className="navigation_btns">
              <button type="button" onClick={backward} disabled={num === 1}>
                <IoArrowBackSharp />
              </button>
              <button
                type="button"
                onClick={forward}
                disabled={num === quizList?.length}
              >
                <IoArrowForward />
              </button>
            </div>
          </div>
        </section>
      )}

      <QuizeResultModal id={id} show={show} quizResult={quizResult} />
    </LmsLayout>
  );
};
export default LmsQuize;
