import "./LessonsInfoList.scss";
import { Fragment, useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { baseUrl, getChapters } from "../../../utils/apiData";
import axios from "axios";
import { LuBookOpen } from "react-icons/lu";
import { useParams } from "react-router-dom";
import PageLoader from "../../lmsComponents/pageLoader/PageLoader";

import { IoMdLock } from "react-icons/io";
import { webContext } from "../../../webContext/WebContext";
import { GoCheckCircleFill } from "react-icons/go";


import { BsArrowLeft } from "react-icons/bs";


const LessonsInfoList = () => {
  const navigate = useNavigate();
  const [btn, setBtn] = useState(0);
  const { toastInfo, setAsModule } = useContext(webContext);

  const { id } = useParams();

  const [loader, setLoader] = useState(false);
  const [chapters, setChapters] = useState([]);

  const [courseName, setCourseName] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [courseId, setCourseId] = useState("");

  const getChaptersApi = async () => {


    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${getChapters}`,
        { module_id: id },
        { headers }
      );
      if (res?.data?.success) {
        setChapters(res?.data?.data?.chapters);
        setCourseName(res?.data?.data?.course?.name);
        setModuleName(res?.data?.data?.moduleName);
        setCourseId(res?.data?.data?.course?._id);        
        setLoader(false);
        console.log(res);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getChaptersApi();
    localStorage.setItem("lesson", 0);
    setAsModule(true);
  }, []);

  return (
    <Fragment>
      {loader ? (
        <PageLoader />
      ) : (
        <section className="lesson_info_list">
          <div className="content_wrap">
            <button type="button" className="back" onClick={() => navigate(`/auth/course/${courseId}`)}><BsArrowLeft /></button>

            <p className="title">{courseName || "-"}</p>
            <h4>{moduleName || "-"}</h4>
            <div className="btns_wrapers">
              <button type="button" className={btn === 0 ? "active" : ""}>
                Lessons
              </button>
            </div>
            {chapters?.length > 0 ? (
              <div className="lessons_grid">
                {chapters?.map((data, index) => {
                  return (
                    <motion.div
                      initial={{ translateY: 48, opacity: 0 }}
                      whileInView={{ translateY: 0, opacity: 1 }}
                      transition={{
                        duration: 0.6,
                        ease: "easeInOut",
                        delay: (index % 2) * 0.1,
                      }}
                      className={`lesson_card ${
                        data?.is_free === "paid" ? "locked" : ""
                      }`}
                      key={data?._id}
                      onClick={() => {
                        if (data?.is_free == "paid") {
                          toastInfo("Course is locked!");
                        } else {
                          navigate(`/auth/chapter/${data?._id}`);
                        }
                      }}
                    >
                      <h5>
                        {data?.is_free === "paid" ? (
                          <IoMdLock />
                        ) : (
                          <LuBookOpen />
                        )}{" "}
                        {data?.chapter || "-"}
                      </h5>

                      <span>
                        {
                          data?.completed && <GoCheckCircleFill />
                        }
                      
                      </span>
                    </motion.div>
                  );
                })}
              </div>
            ) : (
              <p className="text-center my-5">Chapter not found...</p>
            )}
          </div>
        </section>
      )}
    </Fragment>
  );
};
export default LessonsInfoList;
