import "./WebSideBar.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink, useNavigate } from "react-router-dom";
import NormalButton from "../../normalButton/NormalButton";
import { images } from "../../../utils/images";

function WebSideBar({ show, handleClose }) {
  const navigate = useNavigate();

  return (
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <img src={images.logo} alt="logo" />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="side_links">
          <NavLink to="/">Home</NavLink>
          <div className="offering_dd">
            <NavLink to="/offerings">Offerings</NavLink>
            <div className="sub_navs">
              <NavLink to="/offerings/intervention">Intervention</NavLink>
              <NavLink to="/offerings/coaching">Coaching</NavLink>
              <NavLink to="/offerings/consultancy">Consultancy</NavLink>
              <NavLink to="/offerings/courses">Courses</NavLink>
            </div>
          </div>  
          <NavLink to="/blogs">Resources</NavLink>
          <NavLink to="/about">About</NavLink>


          <div className="btn_line">
            <NormalButton
              title="Log in"
              type="bordered"
              clickHandler={() => navigate("/signin")}
              delay={0.1}
            />
            <NormalButton
              title="Contact"
              type="bordered"
              clickHandler={() => navigate("/contact")}
              delay={0.2}
            />
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default WebSideBar;
