import "./OfferingThird.scss";
import { images } from "../../../utils/images";
import { motion } from "framer-motion";

const OfferingThird = () => {
  return (
    <section className="offering_third p_b">
      <div className="content_wrap">
        <div className="bi_sec">
          <div className="text_wrape">
            <motion.p
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 0.1 }}
            >
              Our coachees, mentees, and participants master the game-changing
              practices and methods of making and keeping their teams and areas
              of responsibility world-class and producing assessable, viable,
              and sustainable results.
            </motion.p>
          </div>
          <div className="img_wrap">
            <motion.img
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 0.1 }}
              src={images.offeringB}
              alt="offering"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OfferingThird;
