import { Fragment } from "react";
import "./AboutPillSlider.scss";
import Marquee from "react-fast-marquee";

const AboutPillSlider = ({ about }) => {
  const textData = [
    {
      id: 0,
      title: "8 Countries",
    },
    {
      id: 1,
      title: "20+ Industries",
    },
    {
      id: 2,
      title: "10,000+ Individual Success Stories",
    },
    {
      id: 3,
      title: "50+ Organizations and Institutions",
    },
    {
      id: 4,
      title: "8 Countries",
    },
    {
      id: 5,
      title: "20+ Industries",
    },
    {
      id: 6,
      title: "10,000+ Individual Success Stories",
    },
    {
      id: 7,
      title: "50+ Organizations and Institutions",
    },
  ];

  return (
    <section className="about_text_slider">
      <div className="marque_line p_t p_b">
        {about?.slide_title?.length > 0 ? (
          <Marquee>
            {[...about?.slide_title, ...about?.slide_title].map((item, j) => {
              return (
                <div className="text_box" key={j}>
                  <h3>{item}</h3>
                </div>
              );
            })}
          </Marquee>
        ) : (
          <p>Nothing to see here...</p>
        )}
      </div>
    </section>
  );
};

export default AboutPillSlider;
