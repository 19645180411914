
import LmsLayout from "../lmsComponents/lmsLayout/LmsLayout";
import "./LmsCourse.scss";
import CourseList from "./courseList/CourseList";

const LmsCourse = () => {

    return(
      <LmsLayout>
        <CourseList />
      </LmsLayout>
    )
}

export default LmsCourse;
