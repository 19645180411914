
import { MdAssignment } from "react-icons/md";
import AssignmentSidebar from "../assignmentSidebar/AssignmentSidebar";
import { useState, useEffect, Fragment } from "react";


const AssignmentSec = ({ info, assignmentUpload}) => {

const [assignStatus, setAssignStatus] = useState(false);

 const assignOpen = () => {
    setAssignStatus(true);
  };

  const assignClose = () => {
    setAssignStatus(false);
  };

  return (
    <Fragment>
      <div className="assignment_sec">
        <h5>
          <MdAssignment /> Assignment
        </h5>
        <h6 style={{marginTop:"8px"}}>{info?.assignment || "-"}</h6>
        <p>{info?.assignment_description || "-"}</p>

        {!assignmentUpload && (
          <button type="button" className="secondary_btn" onClick={assignOpen}>
            Upload Assignment
          </button>
        )}
      </div>

      <AssignmentSidebar
        assignStatus={assignStatus}
        assignClose={assignClose}
      />
    </Fragment>
  );
};

export default AssignmentSec;
