import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from "formik";
import countries from "../../../utils/countries";
import { baseUrl, getOrgOptions } from "../../../utils/apiData";
import axios from "axios";
import { useEffect, useState } from "react";


const OrganisationField = ({ type }) => {
    const [orgOptions, setOrgOptions] = useState([]);
  const getOrgOptionsApi = async () => {

    try {
      const res = await axios.post(`${baseUrl}/${getOrgOptions}`, {
        type:`${type}`
      });      
      if(res?.data?.success){
        setOrgOptions(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrgOptionsApi();
  }, [type]);


  return (
    <div className="field_wrap">
      <label>Organisation</label>
      <Field as="select" id="org_id" name="org_id">
        <option value="" disabled>
          - Select -
        </option>
        {orgOptions?.map((data) => {
          return (
            <option value={data?._id} key={data._id}>
              {data?.name}
            </option>
          );
        })}       
      </Field>
    </div>
  );
};

export default OrganisationField;
