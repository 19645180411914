import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl, logout } from "../utils/apiData";

export const webContext = createContext();

const WebContextProvider = ({ children }) => {
  const [addCartEffect, setAddCartEffect] = useState(0);
  const [removeCartEffect, setRemoveCartEffect] = useState(0);
  const [bookMarkEffect, setBookMarkEffect] = useState(0);
  const [assSubEffect, setAssSubEffect] = useState(0);
  const [asModule, setAsModule] = useState(false);

  const toastSuccess = (message) => {
    toast.success(message);
  };

  const toastError = (error) => {
    toast.error(error);
  };

  const toastInfo = (info) => {
    toast.info(info);
  };

  const toastWarning = (warn) => {
    toast.warn(warn);
  };

  const toastClear = () => {
    toast.dismiss();
  };

  // Logout API
  const logOutApi = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const res = await axios.get(`${baseUrl}/${logout}`, { headers });
      toastSuccess("Logged Out!");
      localStorage.clear();
      navigate("/");
    } catch (error) {
      toastError(error?.response?.data?.message || "Log out failed!");
    }
    localStorage.clear();
    navigate("/");
  };

  const passData = {
    addCartEffect,
    setAddCartEffect,
    removeCartEffect,
    setRemoveCartEffect,
    bookMarkEffect,
    setBookMarkEffect,
    asModule,
    setAsModule,
    assSubEffect,
    setAssSubEffect,

    toastSuccess,
    toastError,
    toastInfo,
    toastWarning,
    toastClear,

    logOutApi,
  };

  const navigate = useNavigate();

  return (
    <webContext.Provider value={{ ...passData }}>
      {children}
    </webContext.Provider>
  );
};

export default WebContextProvider;
